import styled from 'styled-components';

const Wrapper = styled.div`
  //box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 8px;
  box-shadow: rgba(0, 0, 0, 0.21) 0px 0.525779px 2.40751px 0px, rgba(0, 0, 0, 0.15) 0px 1.26352px 5.78559px 0px, rgba(0, 0, 0, 0.125) 0px 2.37909px 10.8938px 0px,
    rgba(0, 0, 0, 0.106) 0px 4.2439px 19.4326px 0px, rgba(0, 0, 0, 0.086) 0px 2.93775px 3.3465px 0px, inset rgba(0, 0, 0, 0.06) -1px 20px 20px 0px;
  border-radius: 4px 4px 4px 4px;
  background-color: #fff;
  border: none;
  width: 100%;
  height: 42vh;
  margin-top: 10px;

  @media only screen and (max-width: 795px) {
    height: 400px;
  }
`;

export default Wrapper;
