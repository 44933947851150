const gaishorn2_edit_settings = [
  // {'missing-lamella-request': {
  //     'title' : 'Missing lamella request',
  //     'settings' : [
  //         {'layerId': {'type' : 'status', 'identifier':'id', 'value': 'id'}},
  //         {'numLamellas': {'type' : 'value', 'value': 0}},
  //         {'urgentRequest': {'type' : 'bool', 'value': false}},
  //         ]
  //     }
  // },
  // {'extra-lamella-request': {
  //     'title' : 'Extra lamella request',
  //     'settings' : [
  //         {'lamellaLength': {'type' : 'value', 'value': 16000}},
  //         {'numLamellas': {'type' : 'value', 'value': 0}},
  //         {'lamellaWidth': {'type' : 'status', 'identifier':'lamellaWidth','value': 0}},
  //         {'lamellaThickness': {'type' : 'status', 'identifier':'lamellaThickness','value': 0}},
  //         {'plannedLamellaWidth': {'type' : 'status','identifier':'plannedLamellaWidth', 'value': 0}},
  //         {'plannedLamellaThickness': {'type' : 'status', 'identifier':'plannedLamellaThickness','value': 0}},
  //         {'urgentRequest': {'type' : 'bool', 'value': false}},
  //         ]
  //     }
  // },
  {
    'set-loader-A': {
      settings: [
        { lamellaLength: { type: 'status', identifier: 'boardLengthA', value: 0 } },
        { lamellaWidth: { type: 'status', identifier: 'boardWidthA', value: 0 } },
        { lamellaThickness: { type: 'status', identifier: 'boardThicknessA', value: 0 } }
      ]
    }
  },
  {
    'set-loader-B': {
      title: 'Set Infeed',
      settings: [
        { lamellaLength: { type: 'status', identifier: 'boardLengthB', value: 0 } },
        { lamellaWidth: { type: 'status', identifier: 'boardWidthB', value: 0 } },
        { lamellaThickness: { type: 'status', identifier: 'boardThicknessB', value: 0 } }
      ]
    }
  },
  {
    'set-infeed': {
      title: 'Set Infeed A',
      settings: [
        { rawSortingLoading: { type: 'status', identifier: 'rawSortingLoading', value: null } },
        {
          allowanceRawInfeed: { type: 'boolStatus', identifier: 'allowanceRawInfeed', value: false }
        },
        {
          numberOfBoardsBeforePlaner: {
            type: 'status',
            identifier: 'numberOfBoardsBeforePlaner',
            value: 0
          }
        },
        {
          allowanceMultiplan: { type: 'boolStatus', identifier: 'allowanceMultiplan', value: false }
        }
      ]
    }
  },
  {
    'set-superplan': {
      title: 'Set Superplan',
      settings: [
        {
          sortingInfeedWidthOffset: {
            type: 'status',
            identifier: 'sortingInfeedWidthOffset',
            value: 0
          }
        },
        {
          sortingInfeedThicknessOffset: {
            type: 'status',
            identifier: 'sortingInfeedThicknessOffset',
            value: 0
          }
        },
        {
          sortingInfeedPlannedWidthOffset: {
            type: 'status',
            identifier: 'sortingInfeedPlannedWidthOffset',
            value: 0
          }
        },
        {
          sortingInfeedPlannedThicknessOffset: {
            type: 'status',
            identifier: 'sortingInfeedPlannedThicknessOffset',
            value: 0
          }
        },
        { sortingInfeedWidth: { type: 'status', identifier: 'sortingInfeedWidth', value: 0 } },
        {
          sortingInfeedThickness: { type: 'status', identifier: 'sortingInfeedThickness', value: 0 }
        },
        {
          sortingInfeedPlannedWidth: {
            type: 'status',
            identifier: 'sortingInfeedPlannedWidth',
            value: 0
          }
        },
        {
          sortingInfeedPlannedThicknessOffset: {
            type: 'status',
            identifier: 'sortingInfeedPlannedThicknessOffset',
            value: 0
          }
        }
      ]
    }
  },
  {
    'set-drying': {
      title: 'Set Drying',
      settings: [
        { manualFilling: { type: 'boolStatus', identifier: 'manualFilling', value: false } },
        { manualFillingEtage: { type: 'status', identifier: 'manualFillingEtage', value: 0 } },
        { manualEmptying: { type: 'boolStatus', identifier: 'manualEmptying', value: false } },
        { manualEmptyingID: { type: 'status', identifier: 'manualEmptyingID', value: 0 } },
        { dryingTime: { type: 'status', identifier: 'dryingTime', value: 0 } }
      ]
    }
  },
  {
    'set-zink': {
      title: 'Set Zink',
      settings: [
        {
          zincDoneNumLamellas: {
            type: 'nested-status',
            identifier: 'producing',
            childIdent: 'zincDoneNumLamellas',
            value: 0
          }
        }
      ]
    }
  },
  {
    'set-no-group': {
      title: 'Set No Group',
      settings: [
        { noGroupMode: { type: 'boolStatus', identifier: 'noGroupMode', value: false } },
        {
          noGroupModeWidthTakeaway: {
            type: 'status',
            identifier: 'noGroupModeWidthTakeaway',
            value: 0
          }
        },
        {
          noGroupModeWidthTakeaway: {
            type: 'status',
            identifier: 'noGroupModeWidthTakeaway',
            value: 0
          }
        },
        { noGroupModeCOversize: { type: 'status', identifier: 'noGroupModeCOversize', value: 0 } },
        { noGroupModeLOversize: { type: 'status', identifier: 'noGroupModeLOversize', value: 0 } },
        { kvhOversize: { type: 'status', identifier: 'kvhOversize', value: 0 } },
        { xcutOversize: { type: 'status', identifier: 'xcutOversize', value: 0 } }
      ]
    }
  },
  {
    'set-editjd': {
      settings: [],
      cherryPick: [
        'lamellaThickness',
        'lamellaWidth',
        'lamellaLength',
        'lamellaPlannedThickness',
        'lamellaPlannedWidth',
        'numLamellas',
        'zincDoneNumLamellas',
        'planerDoneNumLamellas',
        'atackerDoneNumLamellas',
        'producingOnZinc',
        'finishedProducingOnZinc',
        'orderTypeString',
        'isKVH',
        'extraLamellaOrder',
        'orientation',
        'type',
        'projectName'
      ]
    }
  }
];

export default gaishorn2_edit_settings;
