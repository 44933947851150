/*
 ** EditView gaishorn2 define fields for editview reducer
 */
import { BSHOrder, BSHLayer, Layer, IncomingPackage, PlanerSetup } from '../../../utils/models';

const gaishorn2_config = {
  bshorderFields: [
    [[{ ...BSHOrder.fields.jobNumber, width: 2 }, { ...BSHOrder.fields.numOfLamellas }, { ...BSHOrder.fields.lamellaLength }, { ...BSHOrder.fields.rawWidth }, { ...BSHOrder.fields.rawThickness }]]
    // [[{ ...BSHOrder.fields.beamPicture }]]
  ],
  bshLayerFields: [
    [
      [{ ...BSHLayer.fields.jobNumber, width: 1.2 }, { ...BSHLayer.fields.client, width: 1.2 }, { ...BSHLayer.fields.numberOfBeams }, { ...Layer.fields.totalNumOfLamellas }],
      [
        { ...Layer.fields.numOfLamellas },
        { ...Layer.fields.lamellaLength },
        { ...Layer.fields.lamellaThickness },
        { ...Layer.fields.lamellaPlannedThickness, width: 2, halfsize: true },
        { ...Layer.fields.lamellaWidth, width: 2, halfsize: true, editable: true },
        { ...Layer.fields.lamellaPlannedWidth, width: 2, halfsize: true }
      ],
      [
        { ...BSHLayer.fields.beamLength },
        { ...BSHLayer.fields.beamWidthRaw },
        { ...BSHLayer.fields.beamWidthFinal },
        { ...BSHLayer.fields.beamThickness },
        { ...BSHLayer.fields.bottomN1D },
        { ...BSHLayer.fields.coreN2M },
        { ...BSHLayer.fields.topN1D },
        { ...BSHLayer.fields.beamPartLengths }
      ]
    ],
    [[{ ...BSHLayer.fields.beamSinglePicture, halfsize: false }]]
  ]
};

export default gaishorn2_config;
