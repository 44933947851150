/*
 *
 * Header reducer
 *
 */
import produce from 'immer';
import _ from 'lodash';
import { DEFAULT_ACTION } from './constants';
import { CLT_STATUS_RECEIVED } from '../Websocket/constants';
import { UPDATE_CLIENT_NAME, UPDATE_CLIENT_IP } from './constants';

export const initialState = {
  packageLoadedSize: 0,
  materialNeededSize: 0,
  sortingQueueSize: 0,
  layersQueueSize: 0,
  ordersQueueSize: 0,
  errorsListSize: 0,
  machineStats: [],
  errorsList: [],
  clientName: '',
  clientIP: ''
};

/* eslint-disable default-case, no-param-reassign */
const headerReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        break;

      case CLT_STATUS_RECEIVED:
        if (_.get(action, 'cltstatus.map') !== undefined) {
          draft.scannedPackagesSize = action?.cltstatus?.map?.scannedPackages?.length || 0;
          draft.packageLoadedSize = action?.cltstatus?.map?.loadedPackages?.length || 0;
          draft.stackedPackagesSize = action?.cltstatus?.map?.stackedPackages?.length || 0;
          draft.materialNeededSize = action?.cltstatus?.map?.rawMaterialNeeded?.length || 0;
          draft.sortingQueueSize = action?.cltstatus?.map?.packageMakingQueue?.length || 0;
          draft.layersQueueSize = action?.cltstatus?.map?.sortedJoinedDimensions?.length || 0;
          draft.ordersQueueSize = action?.cltstatus?.map?.orderQueue?.length || 0;
          draft.cltLayersQueueSize = action?.cltstatus?.map?.cltJoinedDimensions?.length || 0;
          draft.fjOrdersQueueSize = action?.cltstatus?.map?.fjOrderQueue?.length || 0;
          draft.machineStats = action?.cltstatus?.map?.dailyStats || [];
          draft.errorsListSize = action?.cltstatus?.map?.errors?.length || 0;
          draft.errorsList = action?.cltstatus?.map?.errors || [];
        }
        break;

      case UPDATE_CLIENT_NAME:
        draft.clientName = action.clientName;
        break;
      case UPDATE_CLIENT_IP:
        draft.clientIP = action.clientIP;
        break;
    }
  });

export default headerReducer;
