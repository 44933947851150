/**
 *
 * Button.js
 *
 * A common button, if you pass it a prop "route" it'll render a link to a react-router route
 * otherwise it'll render a link with an onclick
 */

import { Children } from 'react';
import PropTypes from 'prop-types';
import Wrapper from './Wrapper';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button1 from '@mui/material/Button';

function Button(props) {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#3c4b64'
      },
      secondary: {
        main: '#11cb5f'
      }
    }
  });

  const button = (
    <ThemeProvider theme={theme}>
      <Button1
        // variant="text"
        className={props.source != undefined && props.source == 'orders' && !props.disabled ? 'orderButton' : `${props.source}`}
        color="primary"
        href={props.href}
        onClick={props.onClick}
        disabled={props.disabled}
        id="link">
        {Children.toArray(props.children)}
      </Button1>
    </ThemeProvider>
  );

  return <Wrapper>{button}</Wrapper>;
}

Button.propTypes = {
  handleRoute: PropTypes.func,
  href: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default Button;
