import modelFieldMessages from '../modelFieldMessages';

export const qualityChoices = {
  1: modelFieldMessages.mlQuality,
  0: modelFieldMessages.dlQuality
};

export const orientationChoices = {
  3: modelFieldMessages.KVHPackageType,
  2: modelFieldMessages.qPackageType,
  1: modelFieldMessages.lPackageType
};

export const rotationChoices = {
  // 2: modelFieldMessages.altRotation,
  1: modelFieldMessages.upRotation,
  0: modelFieldMessages.downRotation
};

export const exitChoices = {
  1: modelFieldMessages.kallesoe,
  2: modelFieldMessages.exit
};

export const pressChoices = {
  0: modelFieldMessages.zPress,
  1: modelFieldMessages.uniPress
};

export const FJchoices = {
  zink1: modelFieldMessages.FJ1,
  zink2: modelFieldMessages.FJ2
};

export const schneider_config = {
  qualityChoices,
  orientationChoices,
  rotationChoices,
  exitChoices,
  pressChoices,
  FJchoices
};

export default schneider_config;
