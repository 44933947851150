/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
// import '@babel/polyfill';

// Import all the third party stuff
import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import history from './app/utils/history';
import 'sanitize.css/sanitize.css';
import './index.css';
// Import root app
import App from './app/containers/App';

// Upgrade to React 18.0 test - ReactDOM.render is no longer supported in React 18
// Instructions from: https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-client-rendering-apis
import { createRoot } from 'react-dom/client';

// Import icons
import LanguageProvider from './app/containers/LanguageProvider';
import { icons } from './app/assets/icons';

// Import Language Provider

// Load the favicon and the .htaccess file
// import '!file-loader?name=[name].[ext]!./app/images/favicon.ico';
// import 'file-loader?name=.htaccess!./app/.htaccess'; // eslint-disable-line import/extensions-

import configureStore from './app/configureStore';

// Import i18n messages
import { translationMessages } from './app/i18n';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Create redux store with history
const initialState = {};

const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');

React.icons = icons;

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, Arial, sans-serif',
    h1: {
      fontFamily: 'Poppins, Arial, sans-serif',
      fontWeight: 700
    },
    h2: {
      fontFamily: 'Poppins, Arial, sans-serif',
      fontWeight: 600
    },
    h3: {
      fontFamily: 'Poppins, Arial, sans-serif',
      fontWeight: 500
    },
    h4: {
      fontFamily: 'Poppins, Arial, sans-serif',
      fontWeight: 500
    },
    h5: {
      fontFamily: 'Poppins, Arial, sans-serif',
      fontWeight: 400
    },
    h6: {
      fontFamily: 'Poppins, Arial, sans-serif',
      fontWeight: 400
    },
    body1: {
      fontFamily: 'Poppins, Arial, sans-serif',
      fontWeight: 400
    },
    body2: {
      fontFamily: 'Poppins, Arial, sans-serif',
      fontWeight: 400
    }
  }
});

// Upgrade to React 18.0 test - ReactDOM.render is no longer supported in React 18
const root = createRoot(MOUNT_NODE);

const render = (messages) => {
  root.render(
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <CssBaseline />
        <LanguageProvider messages={messages}>
          <Router history={history}>
            <React.Suspense fallback={loading}>
              <App />
            </React.Suspense>
          </Router>
        </LanguageProvider>
      </Provider>
    </ThemeProvider>
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./app/i18n', './app/containers/App'], () => {
    // Upgrade to React 18.0 test - ReactDOM.render is no longer supported in React 18
    root.unmount();
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'));
  })
    .then(() =>
      Promise.all([
        import('intl/locale-data/jsonp/en.js'),
        import('intl/locale-data/jsonp/de.js'),
        import('intl/locale-data/jsonp/pt.js'),
        import('intl/locale-data/jsonp/es.js'),
        import('intl/locale-data/jsonp/he.js'),
        import('intl/locale-data/jsonp/fi.js')
      ])
    )
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  require('offline-plugin/runtime').install(); // eslint-disable-line global-require
} else {
  // console.log('process.env.NODE_ENV: ', process.env.NODE_ENV);
  // console.log('process.env: ', process.env);
}
