/*
 *
 * Websocket
 *
 */

import { useEffect, memo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import selectWebsocket from './selectors';
import pako from 'pako';
import PropTypes from 'prop-types';
import reducer from './reducer';
import saga from './saga';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
// import SideMenu from 'containers/SideMenu';
import { cltStatusReceived, newCltStatus, updateComponent, sendToWs, vncClientListReceived, scannerStatusReceived, scannerStatusChanged } from './actions';
// import { refreshView } from '../../actions';
// import createReducer from '../../reducers';
import { indicatorChange } from '../Sidebar/actions';
import { makeSelectArchiveMode } from '../App/selectors';
import { Buffer } from 'buffer';
import { showMessageToClient } from '../WebsocketMessageModal/actions';
import { lastResponseRecieved, solutionOptionsRecieved } from '../ProblemSolutionModal/actions';
import { useRefreshDates } from './RefreshDatesProvider';
import axios from 'axios';
import auth from '../../utils/auth';
import { updateClientName, updateClientIP } from '../Header/actions';

export function Websocket(props) {
  // eslint-disable-line react/prefer-stateless-function
  // console.log('Websocket: ', props.websocket.websocket.cltstatus.map);
  useInjectReducer({ key: 'websocket', reducer });
  useInjectSaga({ key: 'websocket', saga });

  const dispatch = useDispatch();
  const { refreshDatesRef, updateRefreshDates } = useRefreshDates();

  const [hash, setHash] = useState(0);
  const [address, setAddress] = useState('');

  useEffect(() => {
    props.websocket.websocket.websocketObject.onopen = onWebsocketOpen;
    props.websocket.websocket.websocketObject.onmessage = onMessage;
    props.websocket.websocket.websocketObject.onclose = onWebsocketClose;
  }, []);

  useEffect(() => {
    const ip = new Promise((resolve, reject) => {
      const conn = new RTCPeerConnection();
      conn.createDataChannel('');
      conn.createOffer((offer) => conn.setLocalDescription(offer), reject);
      conn.onicecandidate = (ice) => {
        if (ice && ice.candidate && ice.candidate.candidate) {
          try {
            // console.log("HERE?", ice.candidate.candidate);
            resolve(ice.candidate.candidate.split(' ')[4]);
            const myIP = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/.exec(ice.candidate.candidate)[1];
            console.log('myIp', myIP);
            setAddress(myIP);
            dispatch(updateClientIP(myIP));
            conn.close();
          } catch (error) {
            setAddress(ice.candidate.candidate);
          }
        } else {
          setAddress('UNKNOWN');
        }
      };
    });
  }, []);

  useEffect(() => {
    props.websocket.websocket.websocketObject.onmessage = onMessage;
  }, [props.archiveMode]);

  useEffect(() => {
    const interval = setInterval(() => {
      // console.log("SENDING IP", address);
      // dispatch(sendToWs({ ip: address }, 'client-ping'));
      axios
        .post(
          `/api/v1/vnc/xlamclients/terminalPing/`,
          { ip: address },
          {
            headers: {
              Authorization: 'Bearer ' + auth.getToken()
            }
          }
        )
        .then((response) => {
          const data = JSON.parse(response.data);
          dispatch(updateClientName(data.clientName));
        })
        .catch((error) => console.error(error));
    }, 20000);
    return () => clearInterval(interval);
  }, [address]);

  useEffect(() => {
    const interval = setInterval(() => {
      // Fetch all refresh times
      axios
        .get(`/api/v1/productionline/refreshTime/`, {
          headers: {
            Authorization: 'Bearer ' + auth.getToken()
          }
        })
        .then((response) => {
          if (response.status !== 200) {
            throw new Error('Network response was not ok');
          } else {
            const results = _.get(response, 'data.results', []);
            results.map((result) => {
              const { identifier, date } = result;
              const newDate = new Date(date);
              const currentStoredDate = new Date(refreshDatesRef.current[identifier] || 0);

              if (currentStoredDate == 0) {
                updateRefreshDates(identifier, newDate);
              } else if (newDate > currentStoredDate) {
                dispatch(updateComponent('refresh-view', { api: identifier }));
                updateRefreshDates(identifier, newDate);
              }
            });
          }
        })
        .catch((error) => {
          console.error('There was a problem with the fetch operation:', error);
        });
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  function onWebsocketOpen() {
    // console.log('onWebsocketOpen ');
    dispatch(indicatorChange('backend', true));
  }

  function onWebsocketClose() {
    dispatch(indicatorChange('backend', false));
  }

  /*   function refreshViewWithDates(componentName, object, date) {
    const api = _.get(object, 'api', null);
    const currentDate = new Date(date);
    const lastRefreshDate = _.get(refreshDatesRef.current, api, false);

    if (!lastRefreshDate || currentDate - new Date(lastRefreshDate) > 3000) {
      dispatch(updateComponent(componentName, object));
      updateRefreshDates(api, currentDate);
    }
  } */

  function onMessage(msg) {
    dispatch(indicatorChange('backend', true));
    const status = JSON.parse(msg.data);
    if (status.type == 'clt-status' && hash != status.cltstatusHashcode && !props.archiveMode) {
      const start = new Date().getTime();
      const buffer = new Buffer.from(status.data, 'base64');
      const output = pako.inflate(buffer, { to: 'string' });
      const ws = JSON.parse(output);
      ws.inflateCycle = new Date().getTime() - start;
      ws.originalSize = output.length;
      ws.compressedSize = buffer.length;
      //console.log('STATUS: ', hash, status.cltstatusHashcode, ' delay ', (new Date().getTime() - ws.systemTime) / 1000);
      dispatch(cltStatusReceived(ws));
      dispatch(scannerStatusChanged());
      setHash(status.cltstatusHashcode);
    } else if (status.type === 'vnc-client-list') {
      dispatch(vncClientListReceived(status.data));
    } else if (status.type === 'scanner-status') {
      dispatch(scannerStatusReceived());
    } else if (status.type === 'update-component') {
      dispatch(updateComponent(status.data.componentName, status.data.object));
    } else if (status.type === 'extra-lamella-produced') {
      this.props.refreshView('extralamellas');
    } /* else if (status.type === 'refresh-view') {
      refreshViewWithDates(status.data.componentName, status.data.object, status.data.date);
    } */ else if (status.type == 'broadcast_message') {
      dispatch(showMessageToClient(status.data));
    } else if (status.type == 'solution-options') {
      const parsedData = JSON.parse(status.data);
      const { solutions, uuid, validations } = parsedData;
      dispatch(solutionOptionsRecieved(solutions, uuid, validations));
    } else if (status.type == 'last-response') {
      const parsedData = JSON.parse(status.data);
      const { lastResponse, uuid } = parsedData;
      dispatch(lastResponseRecieved(lastResponse, uuid));
    }
  }

  return (
    <div>
      {/* <SideMenu
          status={this.state.status != null ? this.state.status : {}}
          current_time={this.state.current_time != null ? this.state.current_time : ''}
        /> */}
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  websocket: selectWebsocket(),
  archiveMode: makeSelectArchiveMode()
});

const mapDispatchToProps = (dispatch) => {
  return {
    newCltStatus: (status) => dispatch(newCltStatus(status))
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

Websocket.propTypes = {
  dispatch: PropTypes.func,
  status: PropTypes.object,
  children: PropTypes.object,
  refreshView: PropTypes.func,
  cltStatusReceived: PropTypes.func,
  scannerStatusReceived: PropTypes.func,
  newCltStatus: PropTypes.func,
  websocketObject: PropTypes.shape({
    onopen: PropTypes.func,
    onmessage: PropTypes.func,
    onclose: PropTypes.func
  }),
  archiveMode: PropTypes.bool
};

export default compose(withConnect, memo)(Websocket);
