/*
 *
 * Header actions
 *
 */

import { DEFAULT_ACTION, UPDATE_CLIENT_NAME, UPDATE_CLIENT_IP } from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION
  };
}

export function updateClientName(clientName) {
  return {
    type: UPDATE_CLIENT_NAME,
    clientName
  };
}

export function updateClientIP(clientIP) {
  return {
    type: UPDATE_CLIENT_IP,
    clientIP
  };
}
