import { useContext, createElement } from 'react';
import _ from 'lodash';

export function getColor(id) {
  if ((id * 6) % 100 < 10) {
    return '#1abc9c';
  } else if ((id * 7) % 100 < 20) {
    return '#f39c12';
  } else if ((id * 7) % 100 < 30) {
    return '#2ecc71';
  } else if ((id * 7) % 100 < 40) {
    return '#3498db	';
  } else if ((id * 7) % 100 < 50) {
    return '#9b59b6';
  } else if ((id * 7) % 100 < 60) {
    return '#34495e';
  } else if ((id * 7) % 100 < 70) {
    return '#f1c40f';
  } else if ((id * 7) % 100 < 80) {
    return '#d35400';
  } else if ((id * 7) % 100 < 90) {
    return '#ae5a41';
  } else if ((id * 7) % 100 < 100) {
    return '#7f8c8d';
  } else {
    return '#e6e7e8';
  }
}

export function getColorWithoutHash(id) {
  if ((id * 6) % 100 < 10) {
    return '1abc9c';
  } else if ((id * 7) % 100 < 20) {
    return 'f39c12';
  } else if ((id * 7) % 100 < 30) {
    return '2ecc71';
  } else if ((id * 7) % 100 < 40) {
    return '3498db ';
  } else if ((id * 7) % 100 < 50) {
    return '9b59b6';
  } else if ((id * 7) % 100 < 60) {
    return '34495e';
  } else if ((id * 7) % 100 < 70) {
    return 'f1c40f';
  } else if ((id * 7) % 100 < 80) {
    return 'd35400';
  } else if ((id * 7) % 100 < 90) {
    return 'ae5a41';
  } else if ((id * 7) % 100 < 100) {
    return '7f8c8d';
  } else {
    return 'e6e7e8';
  }
}

export function getLayerFromStatus(id, status) {
  if (id === 0) {
    return null;
  }

  if (status != null && status.joinedDimensionsNew !== undefined) {
    for (const index in status.joinedDimensionsNew) {
      if (status.joinedDimensionsNew[index].id !== undefined && status.joinedDimensionsNew[index].id === id) {
        return status.joinedDimensionsNew[index];
      }
    }
  }

  return null;
}

export function getOrderFromStatus(id, status) {
  if (id === 0) {
    return null;
  }

  if (status != null && status.orders !== undefined) {
    for (const index in status.orders) {
      if (status.orders[index].id !== undefined && status.orders[index].id === id) {
        console.log('status.orders[index]', status.orders[index]);
        return status.orders[index];
      }
    }
  }

  return null;
}

export function getOrderFromStatusOrderQueue(id, status) {
  if (id === 0) {
    return null;
  }

  if (status != null && status.orderQueue !== undefined) {
    for (const index in status.orderQueue) {
      if (status.orderQueue[index].id !== undefined && status.orderQueue[index].id === id) {
        console.log('status.orders[index]', status.orderQueue[index]);
        return status.orderQueue[index];
      }
    }
  }

  return null;
}

export function getNumberOfLayersFromOrdersFromStatus(id, status) {
  if (id === 0) {
    return null;
  }

  if (status != null && status.orders !== undefined) {
    for (const index in status.orders) {
      if (status.orders[index].id !== undefined && status.orders[index].id === id) {
        return status.orders[index].layers.length;
      }
    }
  }

  return null;
}

export function getLayerFromOrdersFromStatus(id, status) {
  if (id === 0) {
    return null;
  }

  if (status != null && status.orderQueue !== undefined) {
    for (const index in status.orderQueue) {
      for (const layerIndex in status.orderQueue[index].layers) {
        if (status.orderQueue[index].layers[layerIndex].id !== undefined && status.orderQueue[index].layers[layerIndex].id === id) {
          return status.orderQueue[index].layers[layerIndex];
        }
      }
    }
  }

  return null;
}

export function getOrderFromLayer(id, status) {
  if (id === 0) {
    return null;
  }

  if (status != null && status.orderQueue !== undefined) {
    for (const index in status.orderQueue) {
      for (const layerIndex in status.orderQueue[index].layers) {
        if (status.orderQueue[index].layers[layerIndex].id !== undefined && status.orderQueue[index].layers[layerIndex].id === id) {
          return status.orderQueue[index];
        }
      }
    }
  }

  return null;
}

export function roundToNdecimals(num, places) {
  return +(Math.round(num + 'e+' + places) + 'e-' + places);
}

/* Add transparency value to color */
export function addOpacityToHEXColor(color, opacity) {
  let colour;
  color === undefined ? (colour = '#e1e1e1') : (colour = color);
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return colour + _opacity.toString(16).toUpperCase();
}

export function clearCacheData() {
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });
  console.log('clearCacheData called!');
  // alert('Complete Cache Cleared');
}

export function addDataIntoCache(cacheName, url, response) {
  const data = new Response(JSON.stringify(response));

  if ('caches' in window) {
    // Opening given cache and putting our data into it
    caches.open(cacheName).then((cache) => {
      cache.put(url, data);
      alert('Data Added into cache!');
    });
  }
}

export function loadImageBlur() {
  console.log('loadImageBlur');

  const placeholder = document.querySelector('.placeholder');
  const small = placeholder.querySelector('.img-small');

  // 1: load small image and show it
  const img = new Image();
  img.src = small.src;
  img.onload = function () {
    small.classList.add('loaded');
  };

  // 2: load large image
  const imgLarge = new Image();
  imgLarge.src = placeholder.dataset.large;
  imgLarge.onload = function () {
    imgLarge.classList.add('loaded');
  };
  placeholder.appendChild(imgLarge);
}

export function mapReverse(reverse) {
  const reversed = reverse.map((num, index, reverse) => reverse[reverse.length - 1 - index]);
  return reversed;
}

export const forwardContext =
  (Context) =>
  (Component) =>
  ({ children, ...props }) => {
    const value = useContext(Context);
    const wrapped = createElement(Context.Provider, { value }, children);
    return createElement(Component, props, wrapped);
  };

export function diffWithSign(a, b) {
  return a > b ? Math.abs(a - b) : -Math.abs(a - b);
}

export function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16));
}

export function getStripeCss(item) {
  const colors = _.get(item, 'combinedColors', []);
  let colorString = 'linear-gradient(45deg, ';
  if (_.get(colors, 'length', 0) > 0) {
    const div = (100 - colors.length) / colors.length;
    let current = 0;
    for (let i = 0; i < colors.length; i++) {
      if (i > 0) {
        colorString += ', #99999966 ' + current + '%, #99999966 ' + (current + 1) + '%,';
        current += 1;
      }
      colorString += colors[i] + ' ' + current + '%, ' + colors[i] + ' ' + (current + div) + '%';
      current += div;
    }
  }
  colorString += ')';
  return colorString;
}

export function getStripeCssFromColors(colors) {
  let reversedColors = colors.reverse(); //the most important color must be first
  let gradient = 'linear-gradient(45deg';
  let currentPosition = 0;
  let divisions = [];
  const firstColorWidth = 85; // in percentages

  if (reversedColors.length > 1) {
    divisions.push(firstColorWidth);
    const remainingSpace = (100 - firstColorWidth) / (reversedColors.length - 1);
    for (let i = 1; i < reversedColors.length; i++) {
      divisions.push(remainingSpace);
    }
  } else {
    divisions = [100];
  }

  reversedColors.forEach((color, index) => {
    let nextPosition = currentPosition + divisions[index];
    gradient += `, ${color} ${currentPosition}%, ${color} ${nextPosition}%`;
    currentPosition = nextPosition;
  });

  gradient += ')';
  return gradient;
}

export const getOrdinal = (n) => {
  // Ensure the input number is a valid integer (e.g., removes leading zeros)
  n = parseInt(n, 10);

  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;

  // Add appropriate suffix to the number
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};
