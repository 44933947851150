import modelFieldMessages from './modelFieldMessages';
import globalMessages from '../globalMessages';
import IncomingPackage from './IncomingPackage';
import Order from './Order';
import BSHOrder from './BSHOrder';
import Project from './Project';
import JoinedDimensions from './JoinedDimension';
import Recipe from './Recipe';
import ProjectPlate from './ProjectPlate';
import mainUtilsConfig from './utilsConfig';
import File from './File';
import Product from './Product';
import WoodSupplier from './WoodSupplier';

class ExtraLamellaEntry {
  static fields = {
    id: {
      key: 'id',
      title: modelFieldMessages.lamellaId,
      type: 'string'
    },
    rawThickness: {
      key: 'rawThickness',
      title: modelFieldMessages.rawThickness,
      type: 'string'
    },
    rawWidth: {
      key: 'rawWidth',
      title: modelFieldMessages.rawWidth,
      type: 'string'
    },
    minQuantity: {
      key: 'minQuantity',
      title: modelFieldMessages.minQuantity,
      type: 'string'
    },
    maxQuantity: {
      key: 'maxQuantity',
      title: modelFieldMessages.maxQuantity,
      type: 'string'
    },
    currentQuantity: {
      key: 'currentQuantity',
      title: modelFieldMessages.currentQuantity,
      type: 'string'
    },
    woodType: {
      key: 'woodType',
      type: 'string',
      title: modelFieldMessages.woodType
    },
    quality: {
      key: 'quality',
      type: 'string',
      title: modelFieldMessages.quality
    }
  };

  static actions = {
    plusOne: {
      key: 'plusOne',
      format: {
        type: 'action',
        action: 'changeExtraLamellas',
        icon: 'plusOne',
        style: { fontSize: '18px', color: 'black', cursor: 'pointer' }
      },
      title: modelFieldMessages.plusOne
    },
    minusOne: {
      key: 'minusOne',
      format: {
        type: 'action',
        icon: 'minusOne',
        action: 'changeExtraLamellas',
        style: { fontSize: '18px', color: 'black', cursor: 'pointer' }
      },
      title: modelFieldMessages.minusOne
    },
    deleteExtraLamella: {
      key: 'delete',
      type: 'delete',
      dispatch: 'deleteExtraLamella',
      modal: {
        modal: true,
        buttonText: 'deleteExtraLamella',
        key: 'deleteExtraLamella',
        action: 'deleteExtraLamella',
        actionData: {
          target: 'backend',
          source: 'orders/extralamellas/',
          url: '/api/v1/orders/extralamellas/remove_extralamella/',
          items: 'selected',
          callType: 'POST'
        }
      }
    }
  };
}

class Lamella {
  static fields = {
    lamellaId: {
      key: 'id',
      title: modelFieldMessages.lamellaId
    },
    fehler: {
      key: 'fehler',
      title: modelFieldMessages.fehler,
      format: {
        type: 'boolean'
      }
    },
    length: {
      key: 'length',
      title: modelFieldMessages.length
    },
    glueAmount: {
      key: 'glueAmount',
      title: modelFieldMessages.glueAmount
    },
    packageIdentifier: {
      key: 'incomingPackage',
      title: modelFieldMessages.packageId,
      format: {
        type: 'object',
        str: '{identifier}',
        defaultStr: '',
        keys: ['identifier']
      }
    },
    orderIdentifier: {
      key: 'order',
      title: modelFieldMessages.orderNumber,
      format: {
        type: 'object',
        str: '{orderNumber}',
        defaultStr: '',
        keys: ['orderNumber']
      }
    },
    orderType: {
      key: 'order',
      title: modelFieldMessages.type,
      format: {
        type: 'object',
        str: '{type}',
        defaultStr: '',
        keys: ['type']
      }
    },
    orderDimensions: {
      key: 'order',
      title: modelFieldMessages.lamellaDimensions,
      format: {
        type: 'object',
        str: '{lamellaWidth} x {lamellaThickness} x {lamellaLength} mm',
        defaultStr: '',
        keys: ['lamellaWidth', 'lamellaThickness', 'lamellaLength']
      }
    },
    packageQuality: {
      key: 'incomingPackage',
      title: modelFieldMessages.packageQuality,
      format: {
        type: 'object',
        str: '{quality}',
        defaultStr: '',
        keys: ['quality']
      }
    },
    orderQuality: {
      key: 'order',
      title: modelFieldMessages.orderQuality,
      format: {
        type: 'object',
        str: '{quality}',
        defaultStr: '',
        keys: ['quality']
      }
    },
    dimensions: {
      key: 'incomingPackage',
      title: modelFieldMessages.lamellaDimensions,
      format: {
        type: 'object',
        str: '{lamellaWidth} x {lamellaThickness} x {lamellaLength} mm',
        keys: ['lamellaWidth', 'lamellaThickness', 'lamellaLength']
      }
    },
    dateCreated: {
      key: 'dateCreated',
      title: modelFieldMessages.dateCreated,
      format: {
        type: 'date'
      }
    }
  };
}

class User {
  static userRoleChoices = {
    admin: modelFieldMessages.adminRole,
    worker: modelFieldMessages.workerRole
  };

  static fields = {
    username: {
      key: 'username',
      title: modelFieldMessages.username,
      type: 'string',
      editable: false
    },
    name: {
      key: 'name',
      title: modelFieldMessages.name,
      type: 'string',
      blank: false
    },
    surname: {
      key: 'surname',
      title: modelFieldMessages.surname,
      type: 'string',
      blank: false
    },
    created: {
      key: 'created',
      title: modelFieldMessages.identifierCreatedAt,
      format: {
        type: 'date'
      },
      editable: false
    },
    role: {
      key: 'role',
      type: 'choices',
      title: modelFieldMessages.roleTitle,
      format: {
        type: 'discreteChoices',
        choices: User.userRoleChoices
      }
    },
    password: {
      title: modelFieldMessages.passwordField,
      key: 'new_password',
      type: 'password'
    }
  };
}

class XCutEvent {
  static fields = {
    id: {
      key: 'id',
      type: 'string',
      title: modelFieldMessages.id
    },
    data: {
      key: 'data',
      type: 'string',
      title: modelFieldMessages.data
    }
  };
}

class LineError {
  static fields = {
    errorId: {
      key: 'errorId',
      type: 'string',
      title: modelFieldMessages.errorId
    },
    type: {
      key: 'type',
      type: 'string',
      title: modelFieldMessages.type
    },
    orderId: {
      key: 'orderId',
      type: 'string',
      title: modelFieldMessages.orderId
    },
    description: {
      key: 'description',
      type: 'string',
      title: modelFieldMessages.description
    }
  };

  static actions = {
    deleteError: {
      key: 'delete',
      type: 'deleteError',
      dispatch: 'deleteError',
      modal: {
        modal: true,
        buttonText: 'deleteError',
        key: 'deleteError',
        action: 'deleteError',
        icon: 'deleteIcon',
        actionData: {
          target: 'control',
          source: 'errors',
          identifier: 'error-delete',
          items: 'selected',
          callType: 'POST'
        }
      }
    }
  };
}

class Erp {
  static fields = {
    identifierCode: {
      key: 'identifierCode',
      type: 'string',
      title: modelFieldMessages.identifierCode
    },
    sidePlanning: {
      key: 'sidePlanning',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.sidePlanning
    },
    underChamfer: {
      key: 'underChamfer',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.underChamfer
    },
    upperChamfer: {
      key: 'upperChamfer',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.upperChamfer
    },
    rightMilling: {
      key: 'rightMilling',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.rightMilling
    },
    leftMilling: {
      key: 'leftMilling',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.leftMilling
    },
    sanding: {
      key: 'sanding',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.sanding
    },
    actualRawWidth: {
      key: 'actualRawWidth',
      type: 'string',
      title: modelFieldMessages.actualRawWidth
    },
    actualRawThickness: {
      key: 'actualRawThickness',
      type: 'string',
      title: modelFieldMessages.actualRawThickness
    },
    nominalWidth: {
      key: 'nominalWidth',
      type: 'string',
      title: modelFieldMessages.nominalWidth
    },
    nominalThickness: {
      key: 'nominalThickness',
      type: 'string',
      title: modelFieldMessages.nominalThickness
    },
    minPlaningOffset: {
      key: 'minPlaningOffset',
      type: 'string',
      title: modelFieldMessages.minPlaningOffset
    },
    maxPlaningOffset: {
      key: 'maxPlaningOffset',
      type: 'string',
      title: modelFieldMessages.maxPlaningOffset
    },
    plannedThicknessArboreal: {
      key: 'plannedThicknessArboreal',
      type: 'string',
      title: modelFieldMessages.plannedThickness
    }
  };

  static actions = {
    editErp: {
      key: 'edit',
      type: 'edit',
      identifier: 'editErp',
      title: modelFieldMessages.editErp,
      configuration: {
        model: 'erp',
        url: '/api/v1/erp/planerrefinement/'
      }
    },
    editArborealRawMaterialMap: {
      key: 'edit',
      type: 'edit',
      identifier: 'editArborealRawMaterialMap',
      title: modelFieldMessages.editErp,
      configuration: {
        model: 'erp',
        url: '/api/v1/erp/ArborealRawMaterialMap/'
      }
    },
    deleteArborealRawMaterialMap: {
      key: 'delete',
      type: 'delete',
      dispatch: 'deleteErp',
      title: modelFieldMessages.delete,
      modal: {
        modal: true,
        buttonText: 'delete',
        key: 'delete',
        action: 'delete',
        icon: 'deleteIcon',
        actionData: {
          target: 'backend',
          source: 'erp//',
          url: '/api/v1/erp/ArborealRawMaterialMap/remove_RawMaterialMap/',
          items: 'selected',
          callType: 'POST'
        }
      }
    }
  };
  /* static colors = {
    sidePlanning: '#FFEEFB',
    underChamfer: '#FFEEFB',
    upperChamfer: '#F7C293',
    rightMilling: '#CAFFD8',
    leftMilling: '#CAFFD8',
    sanding: '#CAFFD8',
    addedAt: '#CAFFD8',
  }; */
}

class Event {
  static colors = {
    user_sign_in: '#FFEEFB',
    user_added: '#FFEEFB',
    button_pressed: '#F7C293',
    incoming_package_added: '#CAFFD8',
    incoming_package_added_to_production: '#CAFFD8',
    incoming_package_unloaded: '#CAFFD8',
    incoming_package_used: '#CAFFD8',
    outgoing_package_added: '#CAFFD8',
    error: 'red',
    order_added: '#DBF0F7',
    order_added_to_todo: '#DBF0F7',
    order_removed_from_todo: '#DBF0F7',
    order_deleted: '#DBF0F7',
    order_removed_from_production: '#DBF0F7',
    order_added_to_production: '#DBF0F7',
    bshorder_added: '#DBF0F7',
    bshorder_added_to_todo: '#DBF0F7',
    bshorder_removed_from_todo: '#DBF0F7',
    bshorder_deleted: '#DBF0F7',
    bshorder_removed_from_production: '#DBF0F7',
    bshorder_added_to_production: '#DBF0F7',
    querschieber_fehler: '#FFCCCB',
    fehler: '#FFCCCB',
    info: '#FFEEFB',
    loader_info: 'rgb(255,230,230)',
    zinc_info: 'rgb(251,251,226)',
    drying_info: 'rgb(224,225,251)',
    longlamellastorage_info: 'rgb(229,255,229)',
    shortlamellastorage_info: 'rgb(248,227,248)',
    press_info: 'rgb(255,237,204)'
  };

  static choices = {
    error: modelFieldMessages.error,
    button_pressed: modelFieldMessages.button_pressed,
    info: modelFieldMessages.info,
    loader_info: modelFieldMessages.loader_info,
    zinc_info: modelFieldMessages.zinc_info,
    drying_info: modelFieldMessages.drying_info,
    longlamellastorage_info: modelFieldMessages.longlamellastorage_info,
    shortlamellastorage_info: modelFieldMessages.shortlamellastorage_info,
    press_info: modelFieldMessages.press_info
  };

  static fields = {
    id: {
      key: 'id',
      type: 'string',
      title: modelFieldMessages.id
    },
    addedAt: {
      key: 'added_at',
      title: modelFieldMessages.addedAt,
      format: {
        type: 'date'
      }
    },
    type: {
      key: 'type',
      title: modelFieldMessages.typeTitle,
      format: {
        type: 'discreteChoices',
        choices: Event.choices
      }
    },
    additional_data: {
      key: 'additional_data',
      title: modelFieldMessages.additional_data,
      type: 'string'
    }
  };
}

class Error {
  static colors = {
    user_sign_in: '#FFEEFB',
    user_added: '#FFEEFB',
    button_pressed: '#F7C293',
    incoming_package_added: '#CAFFD8',
    incoming_package_added_to_production: '#CAFFD8',
    incoming_package_unloaded: '#CAFFD8',
    incoming_package_used: '#CAFFD8',
    outgoing_package_added: '#CAFFD8',
    error: 'red',
    order_added: '#DBF0F7',
    order_added_to_todo: '#DBF0F7',
    order_removed_from_todo: '#DBF0F7',
    order_deleted: '#DBF0F7',
    order_removed_from_production: '#DBF0F7',
    order_added_to_production: '#DBF0F7',
    bshorder_added: '#DBF0F7',
    bshorder_added_to_todo: '#DBF0F7',
    bshorder_removed_from_todo: '#DBF0F7',
    bshorder_deleted: '#DBF0F7',
    bshorder_removed_from_production: '#DBF0F7',
    bshorder_added_to_production: '#DBF0F7',
    querschieber_fehler: '#FFCCCB',
    fehler: '#FFCCCB',
    info: '#FFEEFB',
    loader_info: 'rgb(255,230,230)',
    zinc_info: 'rgb(251,251,226)',
    drying_info: 'rgb(224,225,251)',
    longlamellastorage_info: 'rgb(229,255,229)',
    shortlamellastorage_info: 'rgb(248,227,248)',
    press_info: 'rgb(255,237,204)'
  };

  static choices = {
    error: modelFieldMessages.error,
    button_pressed: modelFieldMessages.button_pressed,
    info: modelFieldMessages.info,
    loader_info: modelFieldMessages.loader_info,
    zinc_info: modelFieldMessages.zinc_info,
    drying_info: modelFieldMessages.drying_info,
    longlamellastorage_info: modelFieldMessages.longlamellastorage_info,
    shortlamellastorage_info: modelFieldMessages.shortlamellastorage_info,
    press_info: modelFieldMessages.press_info
  };

  static fields = {
    addedAt: {
      key: 'added_at',
      title: modelFieldMessages.addedAt,
      format: {
        type: 'date'
      }
    },
    type: {
      key: 'type',
      title: modelFieldMessages.typeTitle,
      type: 'string'
    },
    severity: {
      key: 'severity',
      title: modelFieldMessages.severity,
      type: 'string'
    },
    additional_data: {
      key: 'additional_data',
      title: modelFieldMessages.additionalDataTitle,
      type: 'string'
    },
    area: {
      key: 'area',
      title: modelFieldMessages.area,
      type: 'string'
    },
    info: {
      key: 'info',
      title: modelFieldMessages.info,
      type: 'string'
    },
    lastDate: {
      key: 'lastDate',
      title: modelFieldMessages.lastSeen,
      type: 'string'
    },
    startDate: {
      key: 'startDate',
      title: modelFieldMessages.firstSeen,
      type: 'string'
    },
    threshold: {
      key: 'threshold',
      title: modelFieldMessages.threshold,
      type: 'number'
    }
  };
}

class WidthOffset {
  static fields = {
    offset: {
      key: 'offset',
      type: 'string',
      title: modelFieldMessages.offset,
      input: 'number'
    },
    width: {
      key: 'width',
      type: 'string',
      title: modelFieldMessages.width,
      input: 'number'
    }
  };
}

class BeamWidth {
  static fields = {
    width: {
      key: '',
      type: 'string',
      title: modelFieldMessages.width,
      input: 'number',
      conversionType: 'length',
      fromUnit: 'millimeters',
      toUnit: 'inches'
    }
  };
}

class Beams {
  static fields = {
    volume: {
      key: 'volume',
      type: 'string',
      title: modelFieldMessages.volume,
      conversionType: 'volume',
      fromUnit: 'cubic meters',
      toUnit: 'cubic feet'
    },
    finishedProductId: {
      key: 'finishedProductId',
      type: 'string',
      title: modelFieldMessages.finishedProductId
    },
    finishing_notes: {
      key: 'finishing_notes',
      type: 'string',
      title: modelFieldMessages.finishingNotes
    },
    lamellaLength: {
      key: 'lamellaLength',
      type: 'string',
      title: modelFieldMessages.lamellaLength,
      conversionType: 'length',
      fromUnit: 'millimeters',
      toUnit: 'feet'
    },
    lamellaThickness: {
      key: 'lamellaThickness',
      type: 'string',
      title: modelFieldMessages.lamellaThickness,
      conversionType: 'length',
      fromUnit: 'millimeters',
      toUnit: 'feet'
    },
    lamellaWidth: {
      key: 'lamellaWidth',
      type: 'string',
      title: modelFieldMessages.lamellaWidth,
      conversionType: 'length',
      fromUnit: 'millimeters',
      toUnit: 'feet'
    },
    type: {
      key: 'type',
      type: 'string',
      title: modelFieldMessages.type
    },
    mark: {
      key: 'mark',
      type: 'string',
      title: modelFieldMessages.mark
    },
    fire_rating: {
      key: 'fire_rating',
      type: 'string',
      title: modelFieldMessages.fireRating
    },
    group: {
      key: 'group',
      type: 'string',
      title: modelFieldMessages.group
    },
    id: {
      key: 'id',
      type: 'string',
      title: modelFieldMessages.id
    },
    layer: {
      key: 'layer',
      type: 'string',
      title: modelFieldMessages.layer
    },
    layerNo: {
      key: 'layerNo',
      type: 'string',
      title: modelFieldMessages.layerNo
    },
    material: {
      key: 'material',
      type: 'string',
      title: modelFieldMessages.material
    },
    production: {
      key: 'production',
      type: 'string',
      title: modelFieldMessages.production
    },
    project_id: {
      key: 'project_id',
      type: 'string',
      title: modelFieldMessages.projectId
    },
    ripBeam: {
      key: 'ripBeam',
      type: 'string',
      title: modelFieldMessages.ripBeam
    },
    species: {
      key: 'species',
      type: 'string',
      title: modelFieldMessages.species
    },
    structuralReference: {
      key: 'structuralReference',
      type: 'string',
      title: modelFieldMessages.structuralReference
    },
    surfacing: {
      key: 'surfacing',
      type: 'string',
      title: modelFieldMessages.surfacing
    },
    truck: {
      key: 'truck',
      type: 'string',
      title: modelFieldMessages.truck
    },
    weight: {
      key: 'weight',
      type: 'string',
      title: modelFieldMessages.panelWeight,
      conversionType: 'weight',
      fromUnit: 'kilograms',
      toUnit: 'pounds'
    }
  };
}

class LamellaOffset {
  static fields = {
    offset: {
      key: 'volume',
      type: 'string',
      title: modelFieldMessages.volume
    }
  };
}

class LengthOffset {
  static fields = {
    offset: {
      key: 'offset',
      type: 'string',
      title: modelFieldMessages.multipieceOffset
    },
    width: {
      key: 'width',
      type: 'string',
      title: modelFieldMessages.width
    }
  };
}

class Layer {
  static fields = {
    joinedDimensionId: {
      key: 'joinedDimensionId',
      type: 'string',
      title: modelFieldMessages.joinedDimensionId
    },
    layerPicture: {
      key: 'picture',
      type: 'drawPicture',
      title: modelFieldMessages.picture,
      function: 'getCLTPanelImage',
      halfsize: false
    },
    orderId: {
      key: 'orderId',
      type: 'string',
      title: modelFieldMessages.id
    },
    id: {
      key: 'id',
      type: 'string',
      title: modelFieldMessages.id
    },
    layer_erp_id: {
      key: 'layer_erp_id',
      type: 'string',
      title: modelFieldMessages.erpId,
      editable: false
    },
    erpId: {
      key: 'erpId',
      type: 'string',
      title: modelFieldMessages.erpId
    },
    layerNo: {
      key: 'layerNo',
      type: 'string',
      title: modelFieldMessages.layerNo,
      blank: true,
      input: 'number',
      step: '1'
    },
    topTension: {
      key: 'topTension',
      type: 'string',
      title: modelFieldMessages.topTension,
      blank: true,
      input: 'number',
      step: '1'
    },
    topN1D: {
      key: 'topN1D',
      type: 'string',
      title: modelFieldMessages.topN1D,
      blank: true,
      input: 'number',
      step: '1'
    },
    coreN2M: {
      key: 'coreN2M',
      type: 'string',
      title: modelFieldMessages.coreN2M,
      blank: true,
      input: 'number',
      step: '1'
    },
    bottomN1D: {
      key: 'bottomN1D',
      type: 'string',
      title: modelFieldMessages.bottomN1D,
      blank: true,
      input: 'number',
      step: '1'
    },
    bottomTension: {
      key: 'bottomTension',
      type: 'string',
      title: modelFieldMessages.bottomTension,
      blank: true,
      input: 'number',
      step: '1'
    },
    layerId: {
      key: 'layerId',
      type: 'string',
      title: modelFieldMessages.layerId,
      blank: true,
      input: 'number',
      step: '1'
    },
    lamellaWidth: {
      title: modelFieldMessages.lamellaWidth,
      key: 'lamellaWidth',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      step: '1',
      editable: false,
      conditionals: [
        {
          conditional: 'orientation',
          conditionalValue: '1', // long
          conditionalMultiplier: 'numOfLamellas',
          conditionalResult: 'panelWidth', // parent field name
          oversizeFieldName: 'oversize'
        },
        {
          conditional: 'orientation',
          conditionalValue: '2', // cross
          conditionalMultiplier: 'numOfLamellas',
          oversizeFieldName: 'oversizeWidth',
          conditionalResult: 'panelLength' // parent field name
        },
        {
          conditionalExpression: "($item['rawWidth'] - $item['lamellaWidth']) < ($item['maxTakeaway']/10)",
          conditionalFailMessage: 'EXCEEDS MAX TAKEAWAY'
        }
      ],
      modelValidation: [
        {
          valueLimit: 'above',
          fromGlobal: true,
          globalKey: 'maxRawWidth',
          errorMessage: 'rawMaterialTooWide'
        },
        {
          valueLimit: 'below',
          fromGlobal: true,
          globalKey: 'minRawWidth',
          errorMessage: 'rawMaterialTooNarrow'
        }
      ],
      convert: true,
      fromUnit: 'millimeters',
      toUnit: 'inches',
      unitType: 'length'
    },
    lamellaThickness: {
      title: modelFieldMessages.lamellaThickness,
      key: 'rawThickness',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number',
      step: '.01',
      convert: true,
      fromUnit: 'millimeters',
      toUnit: 'inches',
      unitType: 'length'
    },
    rawThickness: {
      title: modelFieldMessages.rawThickness,
      key: 'rawThickness',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number',
      step: '.01',
      convert: true,
      fromUnit: 'millimeters',
      toUnit: 'inches',
      unitType: 'length'
    },
    rawWidth: {
      title: modelFieldMessages.rawWidth,
      key: 'rawWidth',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number',
      step: '.01',
      convert: true,
      fromUnit: 'millimeters',
      toUnit: 'inches',
      unitType: 'length'
    },
    singleLamellaThickness: {
      title: modelFieldMessages.singleLamellaThickness,
      key: 'singleLamellaThickness',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number',
      step: '.01',
      convert: true,
      fromUnit: 'millimeters',
      toUnit: 'inches',
      unitType: 'length'
    },
    lamellaWidthNoLimits: {
      title: modelFieldMessages.lamellaWidth,
      key: 'lamellaWidth',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number',
      step: '.01'
    },
    lamellaLengthNoLimits: {
      title: modelFieldMessages.lamellaLength,
      key: 'lamellaLength',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number',
      step: '.01'
    },
    lamellaLength: {
      title: modelFieldMessages.lamellaLength,
      key: 'lamellaLength',
      type: 'string',
      blank: false,
      input: 'number',
      step: '1',
      editable: false,
      recalculate: true,
      validation: ['float', 'aboveZero'],
      conditionals: [
        {
          conditional: 'orientation',
          conditionalValue: '1', // long
          conditionalResult: 'panelLength' // parent field name
        },
        {
          conditional: 'orientation',
          conditionalValue: '2', // short
          conditionalResult: 'panelWidth' // parent field name
        }
      ]
    },
    lamellaLength_schneider: {
      title: modelFieldMessages.lamellaLength,
      key: 'lamellaLength',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number'
    },
    panelWidth: {
      title: modelFieldMessages.panelWidth,
      key: 'panelWidth',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number'
    },
    lamellaPlannedWidth: {
      title: modelFieldMessages.lamellaPlannedWidth,
      key: 'rawWidth',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      recalculate: true,
      input: 'number',
      step: '1',
      modelValidation: [
        {
          valueLimit: 'above',
          fromGlobal: true,
          globalKey: 'maxRawWidth',
          errorMessage: 'planedMaterialTooWide'
        },
        {
          valueLimit: 'below',
          fromGlobal: true,
          globalKey: 'minRawWidth',
          errorMessage: 'planedMaterialTooNarrow'
        }
      ],
      convert: true,
      fromUnit: 'millimeters',
      toUnit: 'inches',
      unitType: 'length'
    },
    lamellaPlannedThickness: {
      title: modelFieldMessages.lamellaPlannedThickness,
      key: 'lamellaThickness',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number',
      step: '.1',
      convert: true,
      fromUnit: 'millimeters',
      toUnit: 'inches',
      unitType: 'length'
    },
    numOfLamellas: {
      title: modelFieldMessages.numLamellas,
      key: 'numOfLamellas',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number',
      editable: false
    },
    totalNumOfLamellas: {
      title: modelFieldMessages.totalNumOfLamellas,
      key: 'totalNumOfLamellas',
      type: 'string',
      editable: false
    },
    beamWidth: {
      title: modelFieldMessages.beamWidth,
      key: 'beamWidth',
      type: 'string'
    },
    numOfLamellasWood: {
      title: modelFieldMessages.numOfLamellasWood,
      key: 'numOfLamellasWood',
      type: 'string',
      //blank: false,
      //validation: ['float', 'aboveZero'],
      input: 'number',
      editable: false,
      additional_data: {
        renderDependsOn: [
          {
            key: 'conditionalExpression',
            value: '({orientation} >= 4) && ({orientation} <= 5)',
            keys: {
              orientation: '{orientation}'
            }
          }
        ]
      }
    },
    numOfLamellasIns: {
      title: modelFieldMessages.numOfLamellasIns,
      key: 'numOfLamellasIns',
      type: 'string',
      //blank: false,
      //validation: ['float', 'aboveZero'],
      input: 'number',
      editable: false,
      additional_data: {
        renderDependsOn: [
          {
            key: 'conditionalExpression',
            value: '({orientation} >= 4) && ({orientation} <= 5)',
            keys: {
              orientation: '{orientation}'
            }
          }
        ]
      }
    },
    numOfLamellasEditable: {
      title: modelFieldMessages.numLamellas,
      key: 'numOfLamellas',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number',
      editable: true,
      additional_data: {
        renderDependsOn: [
          {
            key: 'conditionalExpression',
            value: '({orientation} >= 1) && ({orientation} <= 2)',
            keys: {
              orientation: '{orientation}'
            }
          }
        ]
      }
    },
    numOfLamellasEditable_noCondition: {
      title: modelFieldMessages.numLamellas,
      key: 'numOfLamellas',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number',
      editable: true,
      default: ''
    },
    inEtage: {
      title: modelFieldMessages.etage,
      key: 'inEtage',
      type: 'string'
    },
    rawDimensions: {
      key: 'rawDimensions',
      title: modelFieldMessages.rawDimensions,
      input: 'number',
      format: {
        type: 'object_from_many',
        str: '{rawThickness} x {rawWidth} x {lamellaLength} mm',
        keys: ['rawThickness', 'rawWidth', 'lamellaLength']
      }
    },
    orderColor: {
      key: 'orderColor',
      type: 'string',
      format: {
        type: 'color'
      }
    },
    lamellaDimensions: {
      key: 'lamellaDimensions',
      title: modelFieldMessages.planedDimensions,
      input: 'number',
      format: {
        type: 'object_from_many',
        str: '{lamellaWidth} x {lamellaThickness}  mm',
        keys: ['lamellaWidth', 'lamellaThickness']
      }
    },
    rawDimensionsConverted: {
      key: 'rawDimensions',
      title: modelFieldMessages.rawDimensions,
      format: {
        type: 'conversion_from_many',
        str: '{rawThickness} x {rawWidth} x {lamellaLength} ',
        keys: [
          { key: 'rawThickness', fromUnit: 'millimeters', toUnit: 'inches' },
          { key: 'rawWidth', fromUnit: 'millimeters', toUnit: 'inches' },
          { key: 'lamellaLength', fromUnit: 'millimeters', toUnit: 'inches' }
        ],
        fromUnitLabel: 'mm',
        toUnitLabel: 'in',
        unitType: 'length'
      }
    },
    lamellaDimensionsConverted: {
      key: 'lamellaDimensions',
      title: modelFieldMessages.planedDimensions,
      format: {
        type: 'conversion_from_many',
        str: '{lamellaWidth} x {lamellaThickness} ',
        keys: [
          { key: 'lamellaWidth', fromUnit: 'millimeters', toUnit: 'inches' },
          { key: 'lamellaThickness', fromUnit: 'millimeters', toUnit: 'inches' }
        ],
        fromUnitLabel: 'mm',
        toUnitLabel: 'in',
        unitType: 'length'
      }
    },
    planingOffset: {
      key: 'planingOffset',
      type: 'string',
      title: modelFieldMessages.planingOffset
    },
    quality: {
      key: 'quality',
      type: 'string',
      title: modelFieldMessages.orderQuality,
      input: 'text',
      default: ''
    },
    qualityN: {
      key: 'qualityN',
      type: 'string',
      title: modelFieldMessages.abfQuality,
      input: 'text',
      default: ''
    },
    layerIdentifier: {
      key: 'layerIdentifier',
      type: 'string',
      title: modelFieldMessages.layerIdentifier,
      input: 'text',
      default: ''
    },
    orientation: {
      key: 'orientation',
      type: 'choices',
      title: modelFieldMessages.orderType,
      format: {
        type: 'discreteChoices',
        choices: mainUtilsConfig.orientationChoices
      },
      validation: ['select'],
      blank: false,
      recalculate: true,
      default: Object.keys(mainUtilsConfig.orientationChoices)[0]
    },
    rotation: {
      key: 'rotation',
      type: 'choices',
      title: modelFieldMessages.rotation,
      format: {
        type: 'discreteChoices',
        choices: mainUtilsConfig.rotationChoices
      },
      validation: ['select'],
      blank: false,
      default: Object.keys(mainUtilsConfig.rotationChoices)[0]
    },
    direction: {
      key: 'direction',
      type: 'choices',
      title: modelFieldMessages.direction,
      format: {
        type: 'discreteChoices',
        choices: mainUtilsConfig.exitChoices
      },
      validation: ['select'],
      blank: false
    },
    qualityChoices: {
      key: 'quality',
      type: 'choices',
      title: modelFieldMessages.quality,
      format: {
        type: 'discreteChoices',
        choices: mainUtilsConfig.qualityChoices
      },
      blank: false
    },
    numKVHLayers: {
      title: modelFieldMessages.numKVHLayers,
      key: 'numKVHLayers',
      type: 'string',
      blank: false
    },
    isBeam: {
      title: modelFieldMessages.isBeam,
      key: 'isBeam',
      type: 'boolean',
      blank: false
    },
    fixedRecipeLayer: {
      title: modelFieldMessages.fixedRecipeLayer,
      key: 'fixedRecipeLayer',
      type: 'boolean',
      blank: true,
      additional_data: {
        renderDependsOn: [
          {
            key: 'conditionalExpression',
            value: '{order} == null',
            keys: {
              order: '{order}'
            }
          }
        ]
      }
    },
    split: {
      title: modelFieldMessages.split,
      key: 'split',
      type: 'boolean',
      blank: false
    },
    isOuter: {
      title: modelFieldMessages.isOuter,
      key: 'isOuter',
      type: 'boolean',
      blank: true
    },
    press: {
      key: 'press',
      type: 'string',
      title: modelFieldMessages.press,
      input: 'text',
      default: ''
    },
    aaa: {
      key: 'aaa',
      type: 'string',
      title: modelFieldMessages.aaa,
      input: 'text',
      default: 'aaa'
    },
    numLamellasPerLayer: {
      title: modelFieldMessages.numLamellasPerLayer,
      key: 'numLamellasPerLayer',
      type: 'string',
      blank: false
    },
    batchId: {
      title: modelFieldMessages.batchId,
      key: 'batchId',
      type: 'string',
      blank: false
    },
    separatorDistance: {
      title: modelFieldMessages.separatorDistance,
      key: 'separatorDistance',
      type: 'string',
      blank: false
    },
    woodType: {
      title: modelFieldMessages.woodType,
      key: 'woodType',
      type: 'string',
      blank: false
    },
    oversizeWidth: {
      key: 'oversizeWidth',
      type: 'string',
      title: modelFieldMessages.oversizeWidth
    },
    oversize: {
      key: 'oversize',
      type: 'string',
      title: modelFieldMessages.oversize
    },
    minTakeaway: {
      key: 'minTakeAway',
      type: 'string',
      title: modelFieldMessages.minTakeaway
    },
    prePlanerTakeOff: {
      key: 'prePlanerTakeOff',
      type: 'string',
      title: modelFieldMessages.prePlanerTakeOff
    },
    maxTakeaway: {
      key: 'maxTakeaway',
      type: 'string',
      title: modelFieldMessages.maxTakeaway
    },
    primingTop: {
      key: 'primingTop',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.primingTop
    },
    primingBottom: {
      key: 'primingBottom',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.primingTop
    },
    chamferTop: {
      key: 'chamferTop',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.chamferTop
    },
    chamferBottom: {
      key: 'chamferBottom',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.chamferBottom
    },
    strengthGrade: {
      key: 'strengthGrade',
      title: modelFieldMessages.grade
    },
    woodTreatment: {
      key: 'woodTreatment',
      type: 'string',
      title: modelFieldMessages.woodTreatment
    },
    assignedToEtage: {
      key: 'assignedToEtage',
      type: 'string',
      title: modelFieldMessages.assignedToEtage
    },
    strengthString: {
      key: 'strengthString',
      type: 'string',
      title: modelFieldMessages.strengthString
    },
    treatment: {
      key: 'treatment',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.treatment
    },
    hollow: {
      key: 'hollow',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.hollow
    },
    material: {
      key: 'material',
      type: 'string',
      title: modelFieldMessages.material
    },
    widthoffsetData: {
      key: 'widthoffset',
      type: 'ListViewAdd',
      recalculate: true,
      additional_data: {
        renderDependsOn: [
          {
            key: 'conditionalExpression',
            value: '({orientation} >= 4) && ({orientation} <= 5)',
            keys: {
              orientation: '{orientation}'
            }
          }
        ],
        validationFunction: function validationFunction(widthoffset, currentFields, globalSettings) {
          return widthoffset.map((item, index) => {
            let errorString = ''; // Define error variable inside the map function
            //console.log('index - ', index, ' - item.width: ', item.width);
            if (item.width % 262.5 !== 0) {
              errorString = 'Width must be a multiple of 262.5.';
            }
            return { id: index, error: errorString.length > 0, errorString: errorString };
          });
        }
      },
      format: [WidthOffset.fields.offset, WidthOffset.fields.width],
      title: modelFieldMessages.insulationData
    },
    recipewidthoffsetData: {
      key: 'widthoffsetrecipe',
      type: 'ListViewAdd',
      recalculate: true,
      additional_data: {
        renderDependsOn: [
          {
            key: 'conditionalExpression',
            value: '({orientation} >= 4) && ({orientation} <= 5)',
            keys: {
              orientation: '{orientation}'
            }
          }
        ],
        validationFunction: function validationFunction(widthoffset, currentFields, globalSettings) {
          return widthoffset.map((item, index) => {
            let errorString = ''; // Define error variable inside the map function
            //console.log('index - ', index, ' - item.width: ', item.width);
            if (item.width % 262.5 !== 0) {
              errorString = 'Width must be a multiple of 262.5.';
            }
            return { id: index, error: errorString.length > 0, errorString: errorString };
          });
        }
      },
      default: [],
      format: [WidthOffset.fields.offset, WidthOffset.fields.width],
      title: modelFieldMessages.insulationData
    },
    insulationDataDND: {
      key: 'insulation',
      type: 'LayerDragAndDrop',
      recalculate: true,
      additional_data: {
        renderDependsOn: [
          {
            key: 'conditionalExpression',
            value: '({orientation} >= 4) && ({orientation} <= 5)',
            keys: {
              orientation: '{orientation}'
            }
          }
        ],
        validationFunction: function validationFunction(insulation, currentFields, globalSettings) {
          console.log('insulation validation', insulation, currentFields, globalSettings);
          return insulation.map((item, index) => {
            let errorString = ''; // Define error variable inside the map function
            //console.log('index - ', index, ' - item.width: ', item.width);
            if (item.width % 262.5 !== 0) {
              errorString = 'Width must be a multiple of 262.5.';
            }
            return { id: index, error: errorString.length > 0, errorString: errorString };
          });
        }
      },
      title: modelFieldMessages.insulationData
    },
    notLamellaData: {
      key: 'widthoffset',
      type: 'ListViewAdd',
      recalculate: true,
      additional_data: {
        renderDependsOn: [
          {
            key: 'conditionalExpression',
            value: '({orientation} == 6)',
            keys: {
              orientation: '{orientation}'
            }
          }
        ]
      },
      format: [LengthOffset.fields.offset, LengthOffset.fields.width],
      title: modelFieldMessages.notLamellaData
    },
    recipenotLamellaData: {
      key: 'widthoffsetrecipe',
      type: 'ListViewAdd',
      recalculate: true,
      additional_data: {
        renderDependsOn: [
          {
            key: 'conditionalExpression',
            value: '({orientation} == 6)',
            keys: {
              orientation: '{orientation}'
            }
          }
        ]
      },
      format: [LengthOffset.fields.offset, LengthOffset.fields.width],
      title: modelFieldMessages.notLamellaData
    },
    lengthoffsetData: {
      key: 'lengthoffset',
      type: 'ListViewAdd',
      recalculate: true,
      additional_data: {
        renderDependsOn: [
          {
            key: 'conditionalExpression',
            value: '({orientation} == 6)',
            keys: {
              orientation: '{orientation}'
            }
          }
        ]
      },
      format: [LengthOffset.fields.offset, LengthOffset.fields.width],
      title: modelFieldMessages.multipieceData
    },
    lengthoffsetDataRecipe: {
      key: 'lengthoffsetrecipe',
      type: 'ListViewAdd',
      recalculate: true,
      additional_data: {
        renderDependsOn: [
          {
            key: 'conditionalExpression',
            value: '({orientation} == 6)',
            keys: {
              orientation: '{orientation}'
            }
          }
        ]
      },
      default: [],
      format: [LengthOffset.fields.offset, LengthOffset.fields.width],
      title: modelFieldMessages.multipieceData
    },
    lamellaDataDND: {
      key: 'insulation',
      type: 'LayerDragAndDrop',
      recalculate: true,
      additional_data: {
        renderDependsOn: [
          {
            key: 'conditionalExpression',
            value: '({orientation} == 6)',
            keys: {
              orientation: '{orientation}'
            }
          }
        ]
      },
      format: [LamellaOffset.fields.offset, LamellaOffset.fields.width],
      title: modelFieldMessages.notLamellaData
    },
    multipieceData: {
      key: 'multipiece',
      type: 'ListViewAdd',
      recalculate: true,
      additional_data: {
        renderDependsOn: [
          {
            key: 'conditionalExpression',
            value: '({orientation} == 6)',
            keys: {
              orientation: '{orientation}'
            }
          }
        ]
      },
      format: [LengthOffset.fields.offset, LengthOffset.fields.width],
      title: modelFieldMessages.multipieceData
    },
    lengthoffsetWidth: {
      title: modelFieldMessages.multipieceWidth,
      key: 'lengthoffsetWidth',
      type: 'string',
      input: 'number',
      editable: true,
      additional_data: {
        renderDependsOn: [
          {
            key: 'conditionalExpression',
            value: '({orientation} == 6)',
            keys: {
              orientation: '{orientation}'
            }
          }
        ]
      }
    },
    multipieceDataDND: {
      key: 'multipiece',
      type: 'LayerDragAndDrop',
      recalculate: true,
      additional_data: {
        renderDependsOn: [
          {
            key: 'conditionalExpression',
            value: '({orientation} == 6)',
            keys: {
              orientation: '{orientation}'
            }
          }
        ]
      },
      title: modelFieldMessages.multipieceData
    },
    multipieceWidth: {
      title: modelFieldMessages.multipieceWidth,
      key: 'lengthoffsetWidth',
      type: 'string',
      input: 'number',
      editable: true,
      defaultValue: {
        value: 0
      },
      additional_data: {
        renderDependsOn: [
          {
            key: 'conditionalExpression',
            value: '({orientation} == 6)',
            keys: {
              orientation: '{orientation}'
            }
          }
        ]
      }
    },
    widthoffsetWidth: {
      title: modelFieldMessages.widthoffsetWidth,
      key: 'widthoffsetWidth',
      type: 'string',
      input: 'number',
      editable: true,
      additional_data: {
        renderDependsOn: [
          {
            key: 'conditionalExpression',
            value: '({orientation} == 5)',
            keys: {
              orientation: '{orientation}'
            }
          }
        ]
      }
    },
    recipeLayerSelect: {
      key: 'recipeLayer',
      type: 'fetchSelect',
      title: modelFieldMessages.recipeLayerSelect,
      format: {
        type: 'urlChoices',
        url: '/api/v1/orders/fixedrecipelayers/all/',
        identifier: 'layerIdentifier',
        dataChange: 'id',
        appendDataToForm: 'recipeLayer'
      }
    },
    getLayerWithRecipeImage: {
      key: 'picture',
      type: 'drawPicture',
      title: modelFieldMessages.picture,
      function: 'getLayerWithRecipeImage'
    }
  };

  static actions = {
    editLayer: {
      key: 'editLayer',
      type: 'edit',
      identifier: 'editOrder',
      title: modelFieldMessages.edit,
      format: {
        type: 'action',
        modalTo: '/perspective/joineddimensions/editjdmodal',
        icon: 'split',
        style: { fontSize: '27px', color: 'black' }
      }
    },
    deleteLayer: {
      key: 'delete',
      type: 'delete',
      dispatch: 'deleteLayer',
      title: modelFieldMessages.delete,
      modal: {
        modal: true,
        buttonText: 'deleteLayer',
        key: 'deleteLayer',
        action: 'deleteLayer',
        icon: 'deleteIcon',
        actionData: {
          target: 'backend',
          source: 'orders/all/?status=todo',
          url: '/api/v1/orders/fixedrecipelayers/remove_layers/',
          items: 'selected',
          callType: 'POST'
        }
      }
    },
    sendLayerToActive: {
      key: 'sendLayerToActive',
      type: 'controlAction',
      dispatch: 'sendLayerToActive',
      format: {
        icon: 'planeIcon',
        style: { fontSize: '27px', color: 'black' }
      },
      adminRequired: false,
      modal: {
        modal: true,
        buttonText: 'sendLayerToActiveTitle',
        key: 'sendLayerToActiveTitle',
        action: 'sendLayerToActiveTitle',
        icon: 'moveIcon',
        title: modelFieldMessages.sendLayerToActiveTitle,
        body: modelFieldMessages.sendLayerToActiveBody,
        actionData: {
          target: 'control',
          source: 'ordersqueue',
          identifier: 'activate-layer',
          items: 'selected',
          callType: 'POST'
        }
      }
    },
    brokenLayer: {
      key: 'action',
      type: 'controlAction',
      format: {
        type: 'action',
        icon: 'BrokenLayer',
        style: { fontSize: '33px', color: 'black' }
      },
      compare: {
        type: 'eval_function_bool',
        str: '{layerCutOnSaw} == true',
        keys: {
          layerCutOnSaw: '{layerCutOnSaw}'
        }
      },
      modal: {
        modal: true,
        buttonText: 'brokenLayer',
        key: 'brokenLayer',
        action: 'brokenLayer',
        actionData: {
          target: 'control',
          source: 'ordersqueue',
          identifier: 'broken-layer-action-for-one-layer',
          items: 'selected',
          callType: 'POST'
        }
      },
      title: globalMessages.brokenLayer
    },
    setCutAtSaw: {
      key: 'action',
      type: 'controlAction',
      format: {
        type: 'action',
        icon: 'CutAtSaw',
        style: { fontSize: '33px', color: 'black' }
      },
      compare: {
        type: 'eval_function_bool',
        str: '{layerCutOnSaw} == false',
        keys: {
          layerCutOnSaw: '{layerCutOnSaw}'
        }
      },
      modal: {
        modal: true,
        buttonText: 'cutAtSaw',
        key: 'cutAtSaw',
        action: 'cutAtSaw',
        actionData: {
          target: 'control',
          source: 'ordersqueue',
          identifier: 'set-layer-cut-at-saw',
          items: 'selected',
          callType: 'POST'
        }
      },
      title: globalMessages.cutAtSaw
    }
  };
}

class LamellaGroup {
  static fields = {
    joinedDimensionId: {
      key: 'layerId',
      type: 'string',
      title: modelFieldMessages.joinedDimensionId
    },
    orderId: {
      key: 'orderId',
      type: 'string',
      title: modelFieldMessages.id
    },
    id: {
      key: 'id',
      type: 'string',
      title: modelFieldMessages.id
    },
    longLamellaLength: {
      title: modelFieldMessages.lamellaLength,
      key: 'longLamellaLength',
      type: 'string',
      blank: false
    },
    lamellasInGroup: {
      title: modelFieldMessages.numLamellas,
      key: 'lamellasInGroup',
      type: 'string',
      blank: false
    },
    storageFloor: {
      title: modelFieldMessages.etage,
      key: 'storageFloor',
      type: 'string'
    },
    numDoneLamellas: {
      title: modelFieldMessages.zincDoneNumLamellas,
      key: 'numDoneLamellas',
      type: 'string'
    },
    producedOnZinc: {
      key: 'finishedProducingOnZinc',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.producedOnZinc
    },
    finishedProducingInsZinc: {
      key: 'finishedProducingInsZinc',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.finishedProducingInsZinc
    },
    producingOnZinc: {
      key: 'producingOnZinc',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.producingOnZinc
    },
    throughZPress: {
      key: 'throughZPress',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.throughZPress
    }
  };

  static actions = {
    moveUp: {
      key: 'moveUp',
      format: {
        type: 'action',
        modalTo: '/perspective/joineddimensions/groupmoveup',
        icon: 'arrowup',
        style: { fontSize: '33px', color: 'black' }
      },
      title: modelFieldMessages.moveUp
    },
    moveDown: {
      key: 'moveDown',
      format: {
        type: 'action',
        modalTo: '/perspective/joineddimensions/groupmovedown',
        icon: 'arrowdown',
        style: { fontSize: '33px', color: 'black' }
      },
      title: modelFieldMessages.moveDown
    }
  };
}

class RecipeLayer {
  static orientationChoices = {
    3: modelFieldMessages.KVHPackageType,
    2: modelFieldMessages.qPackageType,
    1: modelFieldMessages.lPackageType
  };

  static rotationChoices = {
    1: modelFieldMessages.upRotation,
    0: modelFieldMessages.downRotation
  };

  static fields = {
    id: {
      key: 'id',
      type: 'string',
      title: modelFieldMessages.id
    },
    layerNo: {
      key: 'layerNo',
      type: 'string',
      title: modelFieldMessages.layerNo,
      blank: false
    },
    lamellaWidth: {
      title: modelFieldMessages.lamellaWidth,
      key: 'lamellaWidth',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      conditionals: [
        {
          conditional: 'orientation',
          conditionalValue: '1', // long
          conditionalMultiplier: 'numOfLamellas',
          conditionalResult: 'panelWidth', // parent field name
          oversizeFieldName: 'oversize'
        },
        {
          conditional: 'orientation',
          conditionalValue: '2', // cross
          conditionalMultiplier: 'numOfLamellas',
          oversizeFieldName: 'oversizeWidth',
          conditionalResult: 'panelLength' // parent field name
        },
        {
          conditionalExpression: "($item['rawWidth'] - $item['lamellaWidth']) < ($item['maxTakeaway']/10)",
          conditionalFailMessage: 'EXCEEDS MAX TAKEAWAY'
        }
      ]
    },
    lamellaThickness: {
      title: modelFieldMessages.lamellaThickness,
      key: 'lamellaThickness',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero']
    },
    lamellaLength: {
      title: modelFieldMessages.lamellaLength,
      key: 'lamellaLength',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      conditionals: [
        {
          conditional: 'orientation',
          conditionalValue: '1', // long
          conditionalResult: 'panelLength' // parent field name
        },
        {
          conditional: 'orientation',
          conditionalValue: '2', // short
          conditionalResult: 'panelWidth' // parent field name
        }
      ]
    },
    lamellaPlannedWidth: {
      title: modelFieldMessages.lamellaPlannedWidth,
      key: 'rawWidth',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero']
    },
    lamellaPlannedThickness: {
      title: modelFieldMessages.lamellaPlannedThickness,
      key: 'rawThickness',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero']
    },
    numOfLamellas: {
      title: modelFieldMessages.numLamellas,
      key: 'numOfLamellas',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero']
    },
    rawDimensions: {
      key: 'rawDimensions',
      title: modelFieldMessages.rawDimensions,
      format: {
        type: 'object_from_many',
        str: '{rawThickness} x {rawWidth} x {lamellaLength} mm',
        keys: ['rawThickness', 'rawWidth', 'lamellaLength']
      }
    },
    lamellaDimensions: {
      key: 'lamellaDimensions',
      title: modelFieldMessages.planedDimensions,
      format: {
        type: 'object_from_many',
        str: '{lamellaThickness} x {lamellaWidth} mm',
        keys: ['lamellaThickness', 'lamellaWidth']
      }
    },
    quality: {
      key: 'quality',
      type: 'string',
      title: modelFieldMessages.orderQuality,
      blank: false
    },
    orientation: {
      key: 'orientation',
      type: 'choices',
      title: modelFieldMessages.orderType,
      format: {
        type: 'discreteChoices',
        choices: this.orientationChoices
      },
      default: Object.keys(this.orientationChoices)[0],
      blank: false
    },
    rotation: {
      key: 'rotation',
      type: 'choices',
      title: modelFieldMessages.rotationChoices,
      format: {
        type: 'discreteChoices',
        choices: this.rotationChoices
      },
      default: Object.keys(this.rotationChoices)[0],
      blank: false
    },
    direction: {
      key: 'direction',
      type: 'choices',
      title: modelFieldMessages.direction,
      format: {
        type: 'discreteChoices',
        choices: mainUtilsConfig.exitChoices
      },
      blank: false,
      default: Object.keys(mainUtilsConfig.exitChoices)[1]
    },
    numLamellasPerLayer: {
      title: modelFieldMessages.numLamellasPerLayer,
      key: 'numLamellasPerLayer',
      type: 'string',
      blank: false
    },
    oversizeWidth: {
      key: 'oversizeWidth',
      type: 'string',
      title: modelFieldMessages.oversizeWidth
    },
    oversize: {
      key: 'oversize',
      type: 'string',
      title: modelFieldMessages.oversize
    },
    minTakeAway: {
      key: 'minTakeAway',
      type: 'string',
      title: modelFieldMessages.minTakeaway,
      blank: false
    },
    maxTakeaway: {
      key: 'maxTakeaway',
      type: 'string',
      title: modelFieldMessages.maxTakeaway
    },
    hasGlue: {
      key: 'hasGlue',
      type: 'boolean',
      defaultValue: {
        value: true
      },
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.hasGlue
    },
    isOuter: {
      key: 'isOuter',
      type: 'boolean',
      defaultValue: {
        value: false
      },
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.isOuter
    },
    whiteGlue: {
      key: 'whiteGlue',
      type: 'boolean',
      defaultValue: {
        value: false
      },
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.whiteGlue
    },
    minTakeAwayMM: {
      key: 'minTakeAway',
      type: 'string',
      title: modelFieldMessages.minTakeawayMM
    },
    woodType: {
      title: modelFieldMessages.woodType,
      key: 'woodType',
      type: 'string',
      blank: false
    },
    wasteLamellaLength: {
      title: modelFieldMessages.wasteLamellaLength,
      key: 'wasteLamellaLength',
      type: 'string',
      blank: false
    },
    testLamellaLength: {
      title: modelFieldMessages.testLamellaLength,
      key: 'testLamellaLength',
      type: 'string',
      blank: false
    },
    averagePackageSize: {
      title: modelFieldMessages.averagePackageSize,
      key: 'averagePackageSize',
      type: 'string',
      blank: false
    },
    packageTest: {
      title: modelFieldMessages.packageTest,
      key: 'packageTest',
      type: 'string',
      blank: false
    },
    maxEncoderDifferenceFull: {
      title: modelFieldMessages.maxEncoderDifferenceFull,
      key: 'maxEncoderDifferenceFull',
      type: 'string',
      blank: false
    },
    separationDistance: {
      title: modelFieldMessages.separationDistance,
      key: 'separationDistance',
      type: 'string',
      blank: false
    },
    maxDryingEncoder: {
      title: modelFieldMessages.maxDryingEncoder,
      key: 'maxDryingEncoder',
      type: 'string',
      blank: false
    },
    groupBatchSize: {
      title: modelFieldMessages.groupBatchSize,
      key: 'groupBatchSize',
      type: 'string',
      blank: false
    },
    globalZPressOffset: {
      title: modelFieldMessages.globalZPressOffset,
      key: 'globalZPressOffset',
      type: 'string',
      blank: false
    },
    cncOversizeWidth: {
      title: modelFieldMessages.cncOversizeWidth,
      key: 'cncOversizeWidth',
      type: 'string',
      blank: false
    },
    cncOversizeLenght: {
      title: modelFieldMessages.cncOversizeLenght,
      key: 'cncOversizeLenght',
      type: 'string',
      blank: false
    },
    FJMachine: {
      title: modelFieldMessages.FJMachine,
      key: 'FJMachine',
      type: 'string',
      blank: false
    },
    urgentRequest: {
      title: modelFieldMessages.urgentRequest,
      key: 'urgentRequest',
      type: 'boolean',
      defaultValue: {
        value: false
      },
      format: {
        type: 'boolean'
      }
    },
    clearWoodlayers: {
      title: modelFieldMessages.clearWoodlayers,
      key: 'clearWoodlayers',
      type: 'boolean',
      defaultValue: {
        value: false
      },
      format: {
        type: 'boolean'
      }
    },
    widthoffsetData: {
      key: 'widthoffset',
      type: 'ListViewAdd',
      recalculate: true,
      format: [WidthOffset.fields.offset, WidthOffset.fields.width],
      title: modelFieldMessages.widthoffsetData
    }
  };
}

class Compartment {
  static fields = {
    order_id: {
      parent_key: 'order',
      key: 'id',
      type: 'nested_string_item',
      title: modelFieldMessages.order_id
    },
    order_identifier: {
      parent_key: 'order',
      key: 'orderNumber',
      type: 'nested_string_item',
      title: modelFieldMessages.order_identifier
    },
    layer_number: {
      parent_key: 'layer',
      key: 'id',
      type: 'nested_string_item',
      title: modelFieldMessages.layer_number
    },
    actual_number_of_lamellas: {
      key: 'istNumBoards',
      type: 'string',
      title: modelFieldMessages.actual_number_of_lamellas
    },
    set_number_of_lamellas: {
      key: 'sollNumBoards',
      type: 'string',
      title: modelFieldMessages.set_number_of_lamellas
    }
  };
}
class BSHLayer {
  static fields = {
    id: {
      key: 'id',
      type: 'string',
      title: modelFieldMessages.id
    },
    layerId: {
      key: 'layerId',
      type: 'string',
      title: modelFieldMessages.id
    },
    jobNumber: {
      key: 'jobNumber',
      type: 'string',
      title: modelFieldMessages.jobNumber,
      blank: false,
      input: 'text'
    },
    client: {
      key: 'client',
      type: 'string',
      title: modelFieldMessages.client,
      blank: false,
      input: 'text'
    },
    lamellaThickness: {
      title: modelFieldMessages.lamellaThickness,
      key: 'lamellaThickness',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number',
      step: '.01',
      editable: false
    },
    lamellaWidth: {
      title: modelFieldMessages.lamellaThickness,
      key: 'lamellaWidth',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number',
      step: '.01',
      editable: false
    },
    lamellaLength: {
      title: modelFieldMessages.lamellaLength,
      key: 'lamellaLength',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number',
      step: '.01'
    },
    numOfLamellasEditable: {
      title: modelFieldMessages.numLamellas,
      key: 'numOfLamellas',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number',
      editable: true
    },
    numOfPackages: {
      key: 'numOfPackages',
      type: 'string',
      title: modelFieldMessages.numOfPackages
    },
    beamPicture: {
      key: 'picture',
      type: 'drawPicture',
      title: modelFieldMessages.picture,
      function: 'getBSHBeamImage',
      halfsize: false
    },
    beamSinglePicture: {
      key: 'picture',
      type: 'drawPicture',
      title: modelFieldMessages.picture,
      function: 'getBSHBeamSingleImage',
      halfsize: false
    },
    bshCombineLength: {
      key: 'bshCombineLength',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.bshCombineLength
    },
    beamWidth: {
      key: 'beamWidth',
      type: 'ListViewAdd',
      recalculate: true,
      format: [BeamWidth.fields.width],
      title: modelFieldMessages.beamWidth
    },
    beams: {
      key: 'smartlam_f_product',
      type: 'ListViewAdd',
      editable: false,
      format: [
        Beams.fields.finishedProductId,
        Beams.fields.finishing_notes,
        Beams.fields.fire_rating,
        Beams.fields.group,
        Beams.fields.id,
        Beams.fields.lamellaLength,
        Beams.fields.lamellaThickness,
        Beams.fields.lamellaWidth,
        Beams.fields.layer,
        Beams.fields.layerNo,
        Beams.fields.mark,
        Beams.fields.material,
        Beams.fields.production,
        Beams.fields.project_id,
        Beams.fields.ripBeam,
        Beams.fields.species,
        Beams.fields.structuralReference,
        Beams.fields.surfacing,
        Beams.fields.truck,
        Beams.fields.type,
        Beams.fields.volume,
        Beams.fields.weight
      ],
      /*       title: {
        titleType: 'prop',
        key: 'billetSequence'
      } */
      title: function (item) {
        return _.get(item, 'billetSequence', '');
      }
    },
    bottomTension: {
      title: modelFieldMessages.bottomTension,
      key: 'bottomTension',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number',
      step: '.01',
      editable: true
    },
    bottomHeadTakeAway: {
      title: modelFieldMessages.bottomHeadTakeAway,
      key: 'bottomHeadTakeAway',
      type: 'string',
      editable: false,
      convert: true,
      fromUnit: 'millimeters',
      toUnit: 'inches',
      unitType: 'length'
    },
    bottomN1D: {
      title: modelFieldMessages.bottomN1D,
      key: 'bottomN1D',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number',
      step: '.01',
      editable: true,
      recalculate: true
    },
    coreN2M: {
      title: modelFieldMessages.coreN2M,
      key: 'coreN2M',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number',
      step: '.01',
      editable: true,
      recalculate: true
    },
    topN1D: {
      title: modelFieldMessages.topN1D,
      key: 'topN1D',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number',
      step: '.01',
      editable: true,
      recalculate: true
    },
    topTension: {
      title: modelFieldMessages.topTension,
      key: 'topTension',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number',
      step: '.01',
      editable: true,
      recalculate: true
    },
    beamStructure: {
      key: 'beamStructure',
      type: 'groupField',
      recalculate: false,
      //groupKeys: ['bottomTension', 'bottomN1D', 'coreN2M', 'topN1D', 'topTension'],

      groupKeys: [WidthOffset.fields.offset, WidthOffset.fields.width],
      title: modelFieldMessages.beamStructure
    },
    stackingNumOfLayers: {
      key: 'stackingNumOfLayers',
      type: 'string',
      title: modelFieldMessages.stackingNumOfLayers,
      blank: false
    },
    stackingLamellasPerLayer: {
      key: 'stackingLamellasPerLayer',
      type: 'string',
      title: modelFieldMessages.stackingLamellasPerLayer,
      blank: false
    },
    numOfPackages: {
      key: 'numOfPackages',
      title: modelFieldMessages.numOfPackages,
      type: 'string',
      blank: false
    },
    beamLength: {
      title: modelFieldMessages.beamLength,
      key: 'beamLength',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number',
      step: '.01',
      editable: false
    },
    beamWidthRaw: {
      title: modelFieldMessages.beamWidthRaw,
      key: 'beamWidthRaw',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number',
      step: '.01',
      editable: false
    },
    beamThickness: {
      title: modelFieldMessages.beamThickness,
      key: 'beamThickness',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number',
      step: '.01',
      editable: false
    },
    beamWidthFinal: {
      title: modelFieldMessages.beamWidthFinal,
      key: 'beamWidthFinal',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number',
      step: '.01',
      editable: false
    },
    numberOfBeams: {
      title: modelFieldMessages.numberOfBeams,
      key: 'numberOfBeams',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number',
      step: '.01',
      editable: false
    },
    beamPartLengths: {
      title: modelFieldMessages.beamPartLengths,
      key: 'beamPartLengths',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number',
      step: '.01',
      editable: false
    }
  };
}

class SystemctlServices {
  static fields = {
    name: {
      key: 'name',
      type: 'string',
      title: modelFieldMessages.name
    },
    panelIp: {
      key: 'panelIp',
      type: 'string',
      title: modelFieldMessages.ip
    },
    comment: {
      key: 'comment',
      type: 'string',
      title: modelFieldMessages.comment
    },
    status: {
      key: 'status',
      type: 'string',
      title: modelFieldMessages.status
    },
    usage: {
      key: 'usage',
      type: 'string',
      title: modelFieldMessages.usage
    },
    restartService: {
      key: 'restartService',
      format: {
        type: 'urlaction',
        action: 'manageService',
        icon: 'restart',
        data: { action: 'restart' },
        style: { fontSize: '18px', color: 'black', cursor: 'pointer' }
      }
    },
    stopService: {
      key: 'restartService',
      format: {
        type: 'urlaction',
        action: 'manageService',
        icon: 'stop',
        data: { action: 'stop' },
        style: { fontSize: '18px', color: 'black', cursor: 'pointer' }
      }
    },
    restartDaphne: {
      key: 'restartDaphne',
      format: {
        type: 'apiAction',
        action: 'manageService',
        icon: 'moveDown',
        data: { action: 'restart' },
        style: { fontSize: '18px', color: 'black', cursor: 'pointer' }
      }
    }
  };
}

class XlamClient {
  static fields = {
    id: {
      key: 'id',
      type: 'string',
      title: modelFieldMessages.id
    },
    identifier: {
      key: 'identifier',
      type: 'string',
      title: modelFieldMessages.identifier
    },
    lastSeen: {
      key: 'lastSeen',
      type: 'string',
      title: modelFieldMessages.lastSeen
    },
    ipAddress: {
      key: 'ipAddress',
      type: 'string',
      title: modelFieldMessages.ipAddress
    },
    ip_address: {
      key: 'ip_address',
      type: 'string',
      title: modelFieldMessages.ipAddress
    },
    is_central: {
      key: 'is_central',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.isCentral
    },
    identified: {
      key: 'identified',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: globalMessages.identified
    },
    added_at: {
      key: 'added_at',
      type: 'string',
      title: modelFieldMessages.added_at
    },
    description: {
      key: 'description',
      type: 'string',
      title: modelFieldMessages.description
    },
    indicator: {
      key: '',
      type: 'boolIndicator',
      format: {
        type: 'eval_function',
        str: '((new Date().getTime() - new Date("{lastSeen}").getTime())/1000) < 60',
        keys: {
          lastSeen: '{lastSeen}'
        },
        evalFinalString: true
      },
      title: modelFieldMessages.present
    },
    restart_terminal: {
      key: 'Restart Terminals',
      format: {
        type: 'urlaction',
        action: 'restartTerminal',
        icon: 'moveDown',
        // data: { printer: 'outfeedPrinter', label: 'package_label' },
        style: { fontSize: '18px', color: 'black', cursor: 'pointer' }
      }
    }
  };

  static actions = {
    manageServer: {
      key: 'manageServer',
      type: 'manageServer',
      identifier: 'manageServer',
      title: modelFieldMessages.edit,
      configuration: {
        model: 'servers',
        url: '/api/v1/productionline/servers/'
      }
    }
  };
}

class PlanerSetup {
  static fields = {
    s2Thickness: {
      key: 's2Thickness',
      type: 'string',
      title: modelFieldMessages.s2Thickness
    },
    s4Width: {
      key: 's4Width',
      type: 'string',
      title: modelFieldMessages.s4Width
    },
    s5Fold: {
      key: 's5Fold',
      type: 'string',
      title: modelFieldMessages.s5Fold
    },
    s6Fold: {
      key: 's6Fold',
      type: 'string',
      title: modelFieldMessages.s6Fold
    },
    s5Depth: {
      key: 's5Depth',
      type: 'string',
      title: modelFieldMessages.s5Depth
    },
    s6Depth: {
      key: 's6Depth',
      type: 'string',
      title: modelFieldMessages.s6Depth
    },
    s7Enable: {
      key: 's7Enable',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.s7Enable
    },
    s8Enable: {
      key: 's8Enable',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.s8Enable
    },
    turn: {
      key: 'turn',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.turn
    },
    chamferTop: {
      key: 'chamferTop',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.chamferTop
    },
    chamferBottom: {
      key: 'chamferBottom',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.chamferBottom
    },
    identifier: {
      key: 'identifier',
      type: 'string',
      title: modelFieldMessages.identifier
    },
    rawWidth: {
      key: 'rawWidth',
      type: 'string',
      title: modelFieldMessages.rawWidth
    },
    rawThickness: {
      key: 'rawThickness',
      type: 'string',
      title: modelFieldMessages.rawThickness
    },
    lamellaPlannedThickness: {
      key: 'lamellaPlannedThickness',
      type: 'string',
      title: modelFieldMessages.lamellaPlannedThickness
    },
    lamellaPlannedWidth: {
      key: 'lamellaPlannedWidth',
      type: 'string',
      title: modelFieldMessages.lamellaPlannedWidth
    },
    reportedThickness: {
      key: 'reportedThickness',
      type: 'string',
      title: modelFieldMessages.reportedThickness
    },
    reportedWidth: {
      key: 'reportedWidth',
      type: 'string',
      title: modelFieldMessages.reportedWidth
    },
    W1Takeaway: {
      key: 'W1Takeaway',
      type: 'string',
      title: modelFieldMessages.W1Takeaway
    },
    mosserPlanerTakeaway: {
      key: 'W1Takeaway',
      type: 'string',
      title: modelFieldMessages.mosserPlanerTakeaway
    },
    W3Takeaway: {
      key: 'W3Takeaway',
      type: 'string',
      title: modelFieldMessages.W3Takeaway
    },
    quality: {
      key: 'quality',
      type: 'string',
      title: modelFieldMessages.quality
    },
    externalQuality: {
      key: 'externalQuality',
      type: 'string',
      title: modelFieldMessages.externalQuality
    },
    posWhiteGlue: {
      key: 'posWhiteGlue',
      type: 'string',
      title: modelFieldMessages.posWhiteGlue
    },
    posHotmelt: {
      key: 'posHotmelt',
      type: 'string',
      title: modelFieldMessages.posHotmelt
    },
    linesOfWhiteGlue: {
      key: 'linesOfWhiteGlue',
      type: 'string',
      title: modelFieldMessages.linesOfWhiteGlue
    },
    linesOfHotmelt: {
      key: 'linesOfHotmelt',
      type: 'string',
      title: modelFieldMessages.linesOfHotmelt
    },
    splitWood: {
      key: 'splitWood',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.splitWood
    },
    turningZinc: {
      key: 'turningZinc',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.turningZinc
    },
    turningBeforePress: {
      key: 'turningBeforePress',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.turningBeforePress
    },
    turningAfterPress: {
      key: 'turningAfterPress',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.turningAfterPress
    },
    turningEverySecond: {
      key: 'turningEverySecond',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.turningEverySecond
    },
    turningEveryFirst: {
      key: 'turningEveryFirst',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.turningEveryFirst
    },
    addedWidthFromShortToLong: {
      key: 'addedWidthFromShortToLong',
      type: 'string',
      title: modelFieldMessages.addedWidthFromShortToLong
    },
    addedWidthFromLongToShort: {
      key: 'addedWidthFromLongToShort',
      type: 'string',
      title: modelFieldMessages.addedWidthFromLongToShort
    },
    addedWidthEndOfGroup: {
      key: 'addedWidthEndOfGroup',
      type: 'string',
      title: modelFieldMessages.addedWidthEndOfGroup
    },
    disableMixer: {
      key: 'disableMixer',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.disableMixer
    },
    imperialRawWidth: {
      type: 'string',
      key: 'rawWidth',
      convert: true,
      fromUnit: 'millimeters',
      toUnit: 'inches',
      unitType: 'length',
      title: modelFieldMessages.imperialRawWidth,
      format: {
        type: 'conversion_from_many',
        str: '{rawWidth} ',
        keys: [{ key: 'rawWidth', fromUnit: 'millimeters', toUnit: 'inches' }],
        fromUnitLabel: 'mm',
        toUnitLabel: 'in',
        unitType: 'length'
      }
    },
    imperialRawThickness: {
      type: 'string',
      key: 'rawThickness',
      convert: true,
      fromUnit: 'millimeters',
      toUnit: 'inches',
      unitType: 'length',
      title: modelFieldMessages.imperialRawThickness,
      format: {
        type: 'conversion_from_many',
        str: '{rawThickness} ',
        keys: [{ key: 'rawThickness', fromUnit: 'millimeters', toUnit: 'inches' }],
        fromUnitLabel: 'mm',
        toUnitLabel: 'in',
        unitType: 'length'
      }
    },
    imperialReportedThickness: {
      type: 'string',
      key: 'reportedThickness',
      convert: true,
      fromUnit: 'millimeters',
      toUnit: 'inches',
      unitType: 'length',
      title: modelFieldMessages.imperialReportedThickness,
      format: {
        type: 'conversion_from_many',
        str: '{reportedThickness} ',
        keys: [{ key: 'reportedThickness', fromUnit: 'millimeters', toUnit: 'inches' }],
        fromUnitLabel: 'mm',
        toUnitLabel: 'in',
        unitType: 'length'
      }
    },
    imperialReportedWidth: {
      type: 'string',
      key: 'reportedWidth',
      convert: true,
      fromUnit: 'millimeters',
      toUnit: 'inches',
      unitType: 'length',
      title: modelFieldMessages.imperialReportedWidth,
      format: {
        type: 'conversion_from_many',
        str: '{reportedWidth} ',
        keys: [{ key: 'reportedWidth', fromUnit: 'millimeters', toUnit: 'inches' }],
        fromUnitLabel: 'mm',
        toUnitLabel: 'in',
        unitType: 'length'
      },
      recalculate: true
    },
    imperialLamellaPlannedThickness: {
      type: 'string',
      key: 'lamellaPlannedThickness',
      convert: true,
      fromUnit: 'millimeters',
      toUnit: 'inches',
      unitType: 'length',
      title: modelFieldMessages.imperialLamellaPlannedThickness,
      format: {
        type: 'conversion_from_many',
        str: '{lamellaPlannedThickness} ',
        keys: [{ key: 'lamellaPlannedThickness', fromUnit: 'millimeters', toUnit: 'inches' }],
        fromUnitLabel: 'mm',
        toUnitLabel: 'in',
        unitType: 'length'
      }
    },
    imperialLamellaPlannedWidth: {
      type: 'string',
      key: 'lamellaPlannedWidth',
      convert: true,
      fromUnit: 'millimeters',
      toUnit: 'inches',
      unitType: 'length',
      title: modelFieldMessages.imperialLamellaPlannedWidth,
      format: {
        type: 'conversion_from_many',
        str: '{lamellaPlannedWidth} ',
        keys: [{ key: 'lamellaPlannedWidth', fromUnit: 'millimeters', toUnit: 'inches' }],
        fromUnitLabel: 'mm',
        toUnitLabel: 'in',
        unitType: 'length'
      },
      recalculate: true
    },
    imperialW1Takeaway: {
      type: 'string',
      key: 'W1Takeaway',
      convert: true,
      fromUnit: 'millimeters',
      toUnit: 'inches',
      unitType: 'length',
      title: modelFieldMessages.imperialW1Takeaway,
      format: {
        type: 'conversion_from_many',
        str: '{W1Takeaway} ',
        keys: [{ key: 'W1Takeaway', fromUnit: 'millimeters', toUnit: 'inches' }],
        fromUnitLabel: 'mm',
        toUnitLabel: 'in',
        unitType: 'length'
      },
      editable: false
    },
    imperialW3Takeaway: {
      type: 'string',
      key: 'W3Takeaway',
      convert: true,
      fromUnit: 'millimeters',
      toUnit: 'inches',
      unitType: 'length',
      title: modelFieldMessages.imperialW3Takeaway,
      format: {
        type: 'conversion_from_many',
        str: '{W3Takeaway} ',
        keys: [{ key: 'W3Takeaway', fromUnit: 'millimeters', toUnit: 'inches' }],
        fromUnitLabel: 'mm',
        toUnitLabel: 'in',
        unitType: 'length'
      },
      editable: false
    }
  };

  static actions = {
    deletePlanerSetup: {
      key: 'delete',
      type: 'delete',
      dispatch: 'deletePlanerSetup',
      modal: {
        modal: true,
        buttonText: 'deleteIncomingPackage',
        key: 'deletePlanerSetup',
        action: 'deletePlanerSetup',
        actionData: {
          target: 'backend',
          source: 'erp/planerSetup/',
          url: '/api/v1/erp/planerSetup/remove_planer_setups/',
          items: 'selected',
          callType: 'POST'
        }
      }
    },
    editPlanerSetup: {
      key: 'edit',
      type: 'edit',
      identifier: 'editPlanerSetup',
      configuration: {
        model: 'planerSetup',
        url: '/api/v1/erp/planerSetup/'
      }
    },
    deleteArollaPlanerSetup: {
      key: 'delete',
      type: 'delete',
      dispatch: 'deletePlanerSetup',
      modal: {
        modal: true,
        buttonText: 'deleteIncomingPackage',
        key: 'deletePlanerSetup',
        action: 'deletePlanerSetup',
        actionData: {
          target: 'backend',
          source: 'erp/planerSetup/',
          url: '/api/v1/erp/arollaPlanerSetup/remove_planer_setups/',
          items: 'selected',
          callType: 'POST'
        }
      }
    },
    editArollaPlanerSetup: {
      key: 'edit',
      type: 'edit',
      identifier: 'editPlanerSetup',
      configuration: {
        model: 'planerSetup',
        url: '/api/v1/erp/arollaPlanerSetup/'
      }
    }
  };
}

class CeleryTask {
  static fields = {
    id: {
      key: 'id ',
      type: 'string',
      title: modelFieldMessages.id
    },
    task_id: {
      key: 'task_id ',
      type: 'string',
      title: modelFieldMessages.task_id
    },
    task_name: {
      key: 'task_name',
      type: 'string',
      title: modelFieldMessages.task_name
    },
    status: {
      key: 'status',
      type: 'string',
      title: modelFieldMessages.taskStatus
    },
    dateCreated: {
      key: 'date_created',
      title: modelFieldMessages.dateCreated,
      format: {
        type: 'date'
      }
    }
  };

  static actions = {
    stopTask: {
      key: 'delete',
      type: 'delete',
      dispatch: 'deletePlanerSetup',
      modal: {
        modal: true,
        buttonText: 'stopTask',
        key: 'deletePlanerSetup',
        action: 'deletePlanerSetup',
        actionData: {
          target: 'backend',
          source: 'erp/CeleryTasks/',
          url: '/api/v1/erp/CeleryTasks/stop_task/',
          items: 'selected',
          callType: 'POST'
        }
      }
    },
    editPlanerSetup: {
      key: 'edit',
      type: 'edit',
      identifier: 'editPlanerSetup',
      configuration: {
        model: 'planerSetup',
        url: '/api/v1/erp/planerSetup/'
      }
    }
  };
}

class PrinterEvents {
  static fields = {
    id: {
      key: 'id',
      type: 'string',
      title: modelFieldMessages.id
    },
    labelCode: {
      key: 'labelCode',
      type: 'string',
      title: modelFieldMessages.labelCode
    },
    added_at: {
      key: 'added_at',
      title: modelFieldMessages.added_at,
      format: {
        type: 'date'
      }
    },
    printer_identifier: {
      key: 'printer_identifier',
      type: 'string',
      title: modelFieldMessages.printerIdentifier
    },
    labelTemplate: {
      key: 'labelTemplate',
      type: 'string',
      title: modelFieldMessages.labelIdentifier
    }
  };

  static actions = {
    showSticker: {
      key: 'showSticker',
      type: 'showSticker',
      identifier: 'showSticker',
      title: modelFieldMessages.printerEvents,
      configuration: {
        model: 'printerEvents',
        url: 'printer/labelevents/'
      }
    }
  };
}

export {
  Lamella,
  ExtraLamellaEntry,
  Event,
  Error,
  BSHOrder,
  Order,
  IncomingPackage,
  User,
  JoinedDimensions,
  Layer,
  LamellaGroup,
  Recipe,
  RecipeLayer,
  Compartment,
  XCutEvent,
  Project,
  ProjectPlate,
  File,
  Product,
  XlamClient,
  WoodSupplier,
  Erp,
  LineError,
  PlanerSetup,
  BSHLayer,
  CeleryTask,
  WidthOffset,
  Beams,
  BeamWidth,
  SystemctlServices,
  PrinterEvents
};
