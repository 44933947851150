import modelFieldMessages from './modelFieldMessages';
import mainUtilsConfig from './utilsConfig';

class Order {
  static packageTypeChoices = {
    Q: modelFieldMessages.qPackageType,
    L: modelFieldMessages.lPackageType
  };

  static fields = {
    dynamicCheckBox: {
      key: 'check',
      format: {
        type: 'dynamicCheckbox',
        enableFunction: function (item, checkedItems) {
          let result = true;
          //if (item.group != null && !item.group.canBeDeleted) return false;

          if (Array.isArray(checkedItems)) {
            for (let i = 0; i < checkedItems.length; i++) {
              if (
                checkedItems[i].id != item.id &&
                (checkedItems[i].rawThickness != item.rawThickness ||
                  checkedItems[i].rawWidth != item.rawWidth ||
                  checkedItems[i].quality != item.quality ||
                  checkedItems[i].batchCutOnLength != item.batchCutOnLength ||
                  checkedItems[i].splitWood != item.splitWood ||
                  checkedItems[i].assignedPress != item.assignedPress)
              ) {
                result = false;
                break;
              }
            }
          }
          return result;
        }
      }
    },
    combineOrdersCheckBox: {
      key: 'check',
      format: {
        type: 'dynamicCheckbox',
        enableFunction: function (item, checkedItems) {
          let result = true;
          if ((item.status == 'sent to press' || item.status == 'ready for press' || item.status == 'waiting for press' || item.allLayersInStorage) && !item.isKVH) {
          } else {
            return false;
          }

          if (Array.isArray(checkedItems)) {
            for (let i = 0; i < checkedItems.length; i++) {
              if (checkedItems[i].id != item.id && (checkedItems[i].panelWidth != item.panelWidth || checkedItems[i].panelLength != item.panelLength)) {
                result = false;
                break;
              }
            }
          }
          return result;
        }
      }
    },
    group: {
      key: 'group',
      title: modelFieldMessages.group,
      format: {
        type: 'object',
        str: '{id}',
        defaultStr: '',
        keys: ['id']
      }
    },
    orderColor: {
      key: 'orderColor',
      type: 'string',
      format: {
        type: 'color'
      }
    },
    order_m3: {
      title: modelFieldMessages.package_m3,
      format: {
        type: 'object_from_many',
        str: '{cubicMeters} m3',
        keys: ['cubicMeters']
      }
    },
    layers: {
      key: 'layers',
      type: 'foreignkey',
      title: modelFieldMessages.layers
    },
    layerNumber: {
      key: 'layerNumber',
      type: 'string',
      title: modelFieldMessages.layers
    },
    articleCode: {
      key: 'articleCode',
      type: 'string',
      title: modelFieldMessages.articleNumber
    },
    FJMachine: {
      key: 'FJMachine',
      type: 'string',
      title: modelFieldMessages.FJMachine
    },
    assignedPress: {
      key: 'assignedPress',
      type: 'choices',
      title: modelFieldMessages.assignedPress,
      format: {
        type: 'discreteChoices',
        choices: _.get(mainUtilsConfig, 'pressChoices', [])
      },
      validation: ['select'],
      blank: false,
      default: Object.keys(_.get(mainUtilsConfig, 'pressChoices', []))[0]
    },
    assignedFJ: {
      key: 'FJMachine',
      type: 'choices',
      title: modelFieldMessages.assignedFJ,
      format: {
        type: 'discreteChoices',
        choices: _.get(mainUtilsConfig, 'FJchoices', [])
      },
      validation: ['select'],
      blank: false
    },
    glueOpenTimeSec: {
      key: 'glueOpenTime',
      type: 'string',
      title: modelFieldMessages.glueOpenTimeSec
    },
    glueTemp: {
      key: 'glueTemp',
      type: 'string',
      title: modelFieldMessages.glueTemp
    },
    glueAmount: {
      key: 'glueAmount',
      type: 'string',
      title: modelFieldMessages.glueAmount
    },
    pressTime: {
      key: 'pressTime',
      type: 'string',
      title: modelFieldMessages.pressTime
    },
    status: {
      key: 'status',
      type: 'string',
      title: modelFieldMessages.status
    },
    orderCharge: {
      key: 'orderCharge',
      type: 'string',
      title: modelFieldMessages.orderCharge
    },
    salesOrderNumber: {
      key: 'salesOrderNumber',
      type: 'string',
      title: modelFieldMessages.salesOrderNumber
    },
    projectName_schilliger: {
      key: 'projectName',
      type: 'string',
      title: modelFieldMessages.projectName_schilliger
    },
    identifier: {
      key: 'identifier',
      type: 'string',
      title: modelFieldMessages.identifier
    },
    paNr: {
      key: 'paNr',
      type: 'string',
      title: modelFieldMessages.paNr
    },
    noLayers: {
      key: 'layers',
      type: 'arraylength',
      title: modelFieldMessages.layers
    },
    oversizeWidth: {
      key: 'oversizeWidth',
      type: 'string',
      title: modelFieldMessages.oversizeWidth,
      blank: false,
      validation: ['float']
    },
    oversize: {
      key: 'oversize',
      type: 'string',
      title: modelFieldMessages.oversize,
      blank: false,
      validation: ['float']
    },
    id: {
      key: 'id',
      type: 'string',
      title: modelFieldMessages.id,
      input: 'number',
      step: '1'
    },
    pressId: {
      key: 'pressId',
      type: 'string',
      title: modelFieldMessages.pressId
    },
    woodType: {
      key: 'woodType',
      type: 'string',
      title: modelFieldMessages.woodType
    },
    quality: {
      key: 'quality',
      type: 'string',
      title: modelFieldMessages.orderQuality,
      blank: false,
      input: 'text'
    },
    orderNumber: {
      key: 'orderNumber',
      type: 'string',
      title: modelFieldMessages.orderNumber,
      blank: false,
      input: 'text'
    },
    orderImportNumber: {
      key: 'orderImportNumber',
      type: 'string',
      title: modelFieldMessages.orderImportNumber
    },
    client: {
      key: 'client',
      type: 'string',
      title: modelFieldMessages.clientTitle,
      blank: false,
      filterable: true
    },
    pieces: {
      key: 'pieces',
      type: 'string',
      title: modelFieldMessages.piecesTitle,
      width: 2
    },
    incomingLamellaWidth: {
      key: 'incomingLamellaWidth',
      title: modelFieldMessages.incomingLamellaWidth,
      type: 'string'
    },
    incomingLamellaThickness: {
      key: 'incomingLamellaThickness',
      title: modelFieldMessages.incomingLamellaThickness,
      type: 'string'
    },
    lamellaWidth: {
      title: modelFieldMessages.lamellaWidth,
      key: 'lamellaWidth',
      type: 'string',
      blank: false,
      validation: ['float'],
      input: 'number',
      step: '1'
    },
    lamellaThickness: {
      title: modelFieldMessages.lamellaThickness,
      key: 'lamellaThickness',
      type: 'string',
      blank: false,
      validation: ['float'],
      input: 'number',
      step: '.1'
    },
    lamellaThicknessSchneider: {
      title: modelFieldMessages.lamellaThicknessSchneider,
      key: 'lamellaThickness',
      type: 'string',
      blank: false,
      validation: ['float'],
      input: 'number',
      step: '.1'
    },
    lamellaLength: {
      title: modelFieldMessages.lamellaLength,
      key: 'lamellaLength',
      type: 'string',
      blank: false,
      validation: ['float']
    },
    lamellaPlannedWidth: {
      title: modelFieldMessages.lamellaPlannedWidth,
      key: 'rawWidth',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero']
    },
    rawWidth: {
      title: modelFieldMessages.rawWidth,
      key: 'rawWidth',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero']
    },
    rawThickness: {
      title: modelFieldMessages.rawThickness,
      key: 'rawThickness',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero']
    },
    rawThicknessSchneider: {
      title: modelFieldMessages.rawThicknessSchneider,
      key: 'rawThickness',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero']
    },
    lamellaPlannedThickness: {
      title: modelFieldMessages.lamellaPlannedThickness,
      key: 'rawThickness',
      type: 'string',
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number',
      step: '.1'
    },
    pressureAvgFinal: {
      title: modelFieldMessages.pressureAvgFinal,
      key: 'pressureAwgFinal',
      type: 'string'
    },
    addedAt: {
      key: 'addedAt',
      title: modelFieldMessages.addedAt,
      format: {
        type: 'date'
      }
    },
    addedAtDateTime: {
      key: 'addedAt',
      title: modelFieldMessages.addedAt,
      format: {
        type: 'dateTime'
      }
    },
    productionStartedAt: {
      key: 'productionStartedAt',
      title: modelFieldMessages.productionStartedAt,
      type: 'date'
    },
    erpDate: {
      key: 'erpDate',
      title: modelFieldMessages.erpDate,
      format: {
        type: 'date'
      }
    },
    completedAt: {
      key: 'completedAt',
      title: modelFieldMessages.completedAt,
      format: {
        type: 'date'
      }
    },
    lineNumber: {
      key: 'line',
      title: modelFieldMessages.lineNumber
    },
    priority: {
      key: 'priority',
      title: modelFieldMessages.priority
    },
    panelThickness: {
      key: 'panelThickness',
      type: 'string',
      title: modelFieldMessages.panelThickness,
      blank: false,
      input: 'number',
      step: '.01',
      validation: [
        'float',
        'aboveZero',
        {
          type: 'sum',
          childField: 'lamellaThickness',
          desc: 'thicknesses'
        }
      ]
    },
    panelThicknessSchneider: {
      key: 'panelThickness',
      type: 'string',
      title: modelFieldMessages.panelThicknessSchneider,
      blank: false,
      input: 'number',
      step: '.01',
      validation: [
        'float',
        'aboveZero',
        {
          type: 'sum',
          childField: 'lamellaThickness',
          desc: 'thicknesses'
        }
      ]
    },
    numberOfBeams: {
      key: 'numberOfBeams',
      type: 'string',
      title: modelFieldMessages.numberOfBeams,
      input: 'number',
      step: '1'
    },
    panelWidth: {
      key: 'panelWidth',
      type: 'string',
      title: modelFieldMessages.panelWidth,
      blank: false,
      validation: ['float', 'aboveZero'],
      modelValidation: [
        {
          valueLimit: 'above',
          fromGlobal: true,
          globalKey: 'maxPanelWidth',
          errorMessage: 'panelTooWide'
        },
        {
          valueLimit: 'below',
          fromGlobal: true,
          globalKey: 'minPanelWidth',
          errorMessage: 'panelTooNarrow'
        }
      ],
      recalculate: true,
      input: 'number',
      step: '1'
    },
    beamWidth: {
      key: 'panelWidth',
      type: 'string',
      title: modelFieldMessages.beamWidth,
      blank: false,
      validation: ['float', 'aboveZero'],
      recalculate: true,
      input: 'number',
      step: '1'
    },
    mosserPanelWidth: {
      key: 'panelWidth',
      type: 'string',
      title: modelFieldMessages.panelWidth,
      blank: false,
      validation: ['float', 'aboveZero'],
      recalculate: true,
      input: 'number',
      step: '1'
    },
    panelLength: {
      key: 'panelLength',
      type: 'string',
      title: modelFieldMessages.panelLength,
      blank: false,
      validation: ['float', 'aboveZero'],
      recalculate: true,
      input: 'number',
      step: '1',
      modelValidation: [
        {
          valueLimit: 'above',
          fromGlobal: true,
          globalKey: 'maxPanelLength',
          errorMessage: 'panelTooLong'
        },
        {
          valueLimit: 'below',
          fromGlobal: true,
          globalKey: 'minPanelLength',
          errorMessage: 'panelTooShort'
        }
      ]
    },
    mosserPanelLength: {
      key: 'panelLength',
      type: 'string',
      title: modelFieldMessages.panelLength,
      blank: false,
      validation: ['float', 'aboveZero'],
      recalculate: true,
      input: 'number'
    },
    panelsNumber: {
      key: 'panelsNumber',
      type: 'string',
      title: modelFieldMessages.panelsNumber,
      blank: false,
      validation: ['float', 'aboveZero'],
      input: 'number',
      step: '1'
    },
    panelsDone: {
      key: 'panelsDone',
      type: 'string',
      title: modelFieldMessages.panelsDone
    },
    projectName: {
      key: 'projectName',
      type: 'string',
      title: modelFieldMessages.projectName
    },
    projectNumber: {
      key: 'projectNumber',
      type: 'string',
      title: modelFieldMessages.projectNumber
    },
    finishedPackageId: {
      key: 'finishedPackageId',
      type: 'string',
      title: modelFieldMessages.finishedPackageId
    },
    nestingPanelName: {
      key: 'nestingPanelName',
      type: 'string',
      title: modelFieldMessages.nestingPanelName
    },
    nestingPanelNumber: {
      key: 'nestingPanelNumber',
      type: 'string',
      title: modelFieldMessages.nestingPanelNumber
    },
    panelMaterial: {
      key: 'panelMaterial',
      type: 'string',
      title: modelFieldMessages.panelMaterial
    },
    nestingChildren: {
      key: 'nestingChildren',
      type: 'string',
      title: modelFieldMessages.nestingChildren
    },
    panelVolume: {
      key: 'panelVolume',
      type: 'string',
      title: modelFieldMessages.panelVolume
    },
    panelWeight: {
      key: 'panelWeight',
      type: 'string',
      title: modelFieldMessages.panelWeight
    },
    dimensions: {
      key: 'dimensions',
      title: modelFieldMessages.panelDimensions,
      width: 1.5,
      format: {
        type: 'object_from_many',
        str: '{panelThickness} x {panelWidth} x {panelLength} mm',
        keys: ['panelThickness', 'panelWidth', 'panelLength']
      }
    },
    lamellaDimensions: {
      key: 'dimensions',
      title: modelFieldMessages.panelDimensions,
      width: 1.5,
      format: {
        type: 'object_from_many',
        str: '{lamellaWidth} x {lamellaThickness} x {lamellaLength} mm',
        keys: ['lamellaWidth', 'lamellaThickness', 'lamellaLength']
      }
    },
    schneiderRawDimensions: {
      key: 'dimensions',
      title: modelFieldMessages.panelDimensions,
      width: 1.5,
      format: {
        type: 'object_from_many',
        str: '{rawWidth} x {rawThickness} ({lamellaThickness}) mm',
        keys: ['rawWidth', 'rawThickness', 'lamellaThickness']
      }
    },
    kvhDimensions: {
      key: 'Dimensions',
      title: modelFieldMessages.kvhDimensions,
      format: {
        type: 'eval_function',
        str: "{isKVH} == true ? ({lamellaThickness} + 'x' + {lamellaWidth} + 'x' + {lamellaLength}) : ({panelThickness} + 'x' + {panelWidth} + 'x' + {panelLength})",
        keys: {
          isKVH: '{isKVH}',
          lamellaWidth: '{lamellaWidth}',
          lamellaLength: '{lamellaLength}',
          lamellaThickness: '{lamellaThickness}',
          panelThickness: '{panelThickness}',
          panelWidth: '{panelWidth}',
          panelLength: '{panelLength}'
        },
        evalFinalString: true
      }
    },
    schneiderDimensions: {
      key: 'Dimensions',
      title: modelFieldMessages.schneiderDimensions,
      format: {
        type: 'eval_function',
        str: '{rawWidth} x {rawThickness} ({lamellaThickness} / {panelThickness})',
        keys: {
          rawWidth: '{rawWidth}',
          rawThickness: '{rawThickness}',
          lamellaLength: '{lamellaLength}',
          lamellaThickness: '{lamellaThickness}',
          panelThickness: '{panelThickness}'
        },
        evalFinalString: false
      }
    },
    splitWood: {
      key: 'splitWood',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.splitWood
    },
    allLayersInStorage: {
      key: 'allLayersInStorage',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.storage
    },
    canBeDeleted: {
      key: 'canBeDeleted',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.canBeDeleted
    },
    batchCutOnLength: {
      key: 'batchCutOnLength',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.batchCutOnLength
    },
    assignedDimensions: {
      key: 'assignedDimensions',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.assignedDimensions
    },
    additionalInfo: {
      key: 'batchText',
      type: 'string',
      title: modelFieldMessages.additionalInfo
    },
    orderDimensions: {
      key: 'dimensions',
      title: modelFieldMessages.lamellaDimensions,
      width: 1.5,
      format: {
        type: 'object_from_many',
        str: '{panelThickness} x {orderWidth} x {panelLength} mm',
        keys: ['panelThickness', 'orderWidth', 'panelLength']
      }
    },
    packageDimensions: {
      key: 'package_dimensions',
      title: modelFieldMessages.lamellaDimensions,
      width: 1.5,
      format: {
        type: 'object_from_many',
        str: '{lamellaThickness} x {lamellaWidth} x {lamellaLength} mm',
        keys: ['lamellaWidth', 'lamellaThickness', 'lamellaLength']
      }
    },
    incomingPackageDimensions: {
      key: 'package_dimensions',
      title: modelFieldMessages.incomingLamellaDimensions,
      width: 1.5,
      format: {
        type: 'object_from_many',
        str: '{incomingLamellaThickness} x {incomingLamellaWidth} mm',
        keys: ['incomingLamellaThickness', 'incomingLamellaWidth']
      }
    },
    type: {
      key: 'type',
      type: 'choices',
      title: modelFieldMessages.orderType,
      format: {
        type: 'discreteChoices',
        choices: Order.packageTypeChoices
      }
    },
    isKVH: {
      key: 'isKVH',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.isKVH
    },
    isBeam: {
      key: 'isBeam',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.isBeam
    },
    largeFingerJoint: {
      key: 'largeFingerJoint',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.largeFingerJoint
    },
    chamfer: {
      key: 'chamfer',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.chamfer
    },
    jobCode: {
      key: 'jobCode',
      type: 'string',
      title: modelFieldMessages.jobCode,
      blank: false
    },
    actualPanels: {
      key: 'actualPanels',
      type: 'string',
      title: modelFieldMessages.actualPanels
    },
    rowsNumber: {
      key: 'rowsNumber',
      type: 'string',
      title: modelFieldMessages.rowsNumber,
      blank: false
    },
    colsNumber: {
      key: 'colsNumber',
      type: 'string',
      title: modelFieldMessages.colsNumber,
      blank: false
    },
    kvhBoards: {
      key: 'kvhBoards',
      type: 'string',
      title: modelFieldMessages.kvhBoards,
      blank: false
    },
    orderPicture: {
      key: 'picture',
      type: 'drawPicture',
      title: modelFieldMessages.picture,
      function: 'getCLTOrderImage',
      halfsize: true
    },
    panelPicture: {
      key: 'picture',
      type: 'drawPicture',
      title: modelFieldMessages.picture,
      function: 'getCLTPanelImage',
      halfsize: false
    },
    orderModel: {
      key: 'picture',
      type: 'drawPicture',
      title: 'model title',
      function: 'getOrderModel',
      halfsize: true
    },
    masterpanel: {
      key: 'masterpanel',
      type: 'string',
      title: modelFieldMessages.masterpanel,
      input: 'text',
      default: ''
    },
    masterId: {
      key: 'masterId',
      type: 'string',
      title: modelFieldMessages.masterId,
      input: 'text',
      default: ''
    },
    combinedPressId: {
      key: 'combinedPressId',
      type: 'string',
      title: modelFieldMessages.combinedPressId,
      input: 'text',
      default: ''
    },
    masterErpId: {
      key: 'masterErpId',
      type: 'string',
      title: modelFieldMessages.masterErpId,
      input: 'text',
      default: ''
    },
    recipeSelect: {
      key: 'recipe',
      type: 'fetchSelect',
      title: modelFieldMessages.recipeSelect,
      format: {
        type: 'urlChoices',
        url: '/api/v1/orders/allrecipes/',
        identifier: 'identifier',
        dataChange: 'id',
        appendDataToForm: 'recipe'
      }
    },
    projectSelect: {
      key: 'project',
      type: 'fetchSelect',
      title: modelFieldMessages.projectSelect,
      format: {
        type: 'urlChoices',
        url: '/api/v1/orders/projects/',
        identifier: 'projectNumber',
        dataChange: 'id',
        appendDataToForm: 'project'
      }
    },
    inPackageAlready: {
      key: 'inPackageAlready',
      type: 'boolean',
      format: {
        type: 'boolean'
      },
      title: modelFieldMessages.inPackageAlready
    },
    inputArticleNumber: {
      key: 'inputArticleNumber',
      type: 'string',
      title: modelFieldMessages.inputArticleNumber
    },
    outputArticleNumber: {
      key: 'outputArticleNumber',
      type: 'string',
      title: modelFieldMessages.outputArticleNumber
    },
    erpId: {
      key: 'erp_id',
      type: 'string',
      title: modelFieldMessages.erpId
    },
    inputQuality: {
      key: 'inputQuality',
      type: 'string',
      title: modelFieldMessages.inputQuality
    },
    outputQuality: {
      key: 'outputQuality',
      type: 'string',
      title: modelFieldMessages.outputQuality
    }
  };

  static actions = {
    deleteOrder: {
      key: 'delete',
      type: 'delete',
      dispatch: 'deleteOrder',
      title: modelFieldMessages.delete,
      modal: {
        modal: true,
        buttonText: 'deleteOrder',
        key: 'deleteOrder',
        action: 'deleteOrder',
        icon: 'deleteIcon',
        actionData: {
          target: 'backend',
          source: 'orders/all/?status=ready',
          url: '/api/v1/orders/all/remove_orders/',
          items: 'selected',
          callType: 'POST'
        }
      }
    },
    deleteOptimizedOrder: {
      key: 'delete',
      type: 'delete',
      dispatch: 'deleteOrder',
      title: modelFieldMessages.delete,
      modal: {
        modal: true,
        buttonText: 'deleteOrder',
        key: 'deleteOrder',
        action: 'deleteOrder',
        icon: 'deleteIcon',
        actionData: {
          target: 'backend',
          source: 'orders/all/?status=optimized',
          url: '/api/v1/orders/all/delete_optimized/',
          items: 'selected',
          callType: 'POST'
        }
      }
    },
    deleteControlOrder: {
      key: 'delete',
      type: 'delete',
      dispatch: 'deleteOrder',
      modal: {
        modal: true,
        buttonText: 'deleteOrder',
        key: 'deleteOrder',
        action: 'deleteOrder',
        icon: 'deleteIcon',
        actionData: {
          target: 'control',
          source: 'ordersqueue',
          identifier: 'order-to-remove-from-queue',
          items: 'selected',
          callType: 'POST'
        }
      },
      title: modelFieldMessages.delete
    },
    switchFJ: {
      key: 'action',
      type: 'controlAction',
      format: {
        compareField: 'availableForSwitch',
        icon: 'switchIcon',
        style: { fontSize: '27px', color: 'black' }
      },
      modal: {
        modal: true,
        buttonText: 'switchFJ',
        key: 'switchFJ',
        action: 'switchFJ',
        icon: 'switchIcon',
        actionData: {
          target: 'control',
          source: 'ordersqueue',
          identifier: 'switch-order-fj',
          items: 'selected',
          callType: 'POST'
        }
      },
      title: modelFieldMessages.switchFJ
    },
    printCLTOrderLabel: {
      key: 'action',
      type: 'controlAction',
      format: {
        icon: 'printIcon',
        style: { fontSize: '27px', color: 'black' }
      },
      modal: {
        modal: true,
        buttonText: 'testCLTpanelPrinter',
        key: 'testCLTpanelPrinter',
        action: 'testCLTpanelPrinter',
        icon: 'printIcon',
        actionData: {
          target: 'control',
          source: 'ordersqueue',
          identifier: 'print-CLTOrder-Label',
          items: 'selected',
          callType: 'POST'
        }
      },
      title: modelFieldMessages.testCLTpanelPrinter
    },
    editOrder: {
      key: 'edit',
      type: 'edit',
      identifier: 'editOrder',
      title: modelFieldMessages.edit,
      configuration: {
        model: 'order',
        url: '/api/v1/orders/all/'
      }
    },

    editBeam: {
      key: 'edit',
      type: 'edit',
      identifier: 'editOrder',
      title: modelFieldMessages.edit,
      configuration: {
        model: 'beam',
        url: '/api/v1/orders/all/'
      }
    },

    pressOrder: {
      key: 'startOrder',
      dispatch: 'pressOrder',
      modal: {
        modal: true,
        buttonText: 'pressOrder',
        key: 'pressOrder',
        action: 'pressOrder',
        icon: 'startIcon',
        actionData: {
          target: 'control',
          source: 'ordersqueue',
          identifier: 'press-order',
          items: 'selected',
          callType: 'POST'
        }
      },
      adminRequired: false,
      format: {
        type: 'action',
        icon: 'start',
        style: { fontSize: '33px', color: 'black' }
      },
      title: modelFieldMessages.startOrder
    },

    editControlOrder: {
      adminRequired: false,
      key: 'editControlOrder',
      format: {
        type: 'action',
        modalTo: '/api/v1/orders/all/',
        icon: 'split',
        style: { fontSize: '27px', color: 'black' }
      },
      title: modelFieldMessages.edit
    },

    editControlOrderNoLayers: {
      adminRequired: false,
      key: 'editControlOrder',
      format: {
        type: 'action',
        modalTo: '/api/v1/orders/all/',
        icon: 'split',
        style: { fontSize: '27px', color: 'black' }
      },
      title: modelFieldMessages.edit
    },

    moveOrderToDone: {
      key: 'moveToDone',
      type: 'moveToDone',
      dispatch: 'moveToDone',
      modal: {
        modal: true,
        buttonText: 'moveToDone',
        key: 'moveToDone',
        action: 'moveToDone',
        icon: 'moveIcon',
        actionData: {
          target: 'control',
          source: 'ordersqueue',
          identifier: 'order-to-move-to-done',
          items: 'selected',
          callType: 'POST'
        },
        style: { fontSize: '27px', color: 'black' }
      },
      title: modelFieldMessages.finish
    },
    approvedForPlaning: {
      key: 'approvedForPlaning',
      type: 'controlAction',
      dispatch: 'approvedForPlaning',
      format: {
        compareField: 'planingConfirmed',
        icon: 'planeIcon',
        style: { fontSize: '27px', color: 'black' }
      },
      adminRequired: false,
      modal: {
        modal: true,
        buttonText: 'approvedForPlaningTitle',
        key: 'approvedForPlaningTitle',
        action: 'approvedForPlaningTitle',
        icon: 'moveIcon',
        title: modelFieldMessages.approvedForPlaningTitle,
        body: modelFieldMessages.approvedForPlaningBody,
        actionData: {
          target: 'control',
          source: 'ordersqueue',
          identifier: 'confirm-planing',
          items: 'selected',
          callType: 'POST'
        }
      }
    },
    activateOrder: {
      key: 'activateOrder',
      type: 'controlAction',
      dispatch: 'activateOrder',
      format: {
        icon: 'planeIcon',
        style: { fontSize: '27px', color: 'black' }
      },
      adminRequired: false,
      modal: {
        modal: true,
        buttonText: 'activateOrderTitle',
        key: 'activateOrderTitle',
        action: 'activateOrderTitle',
        icon: 'moveIcon',
        title: modelFieldMessages.sendPanelToPLCTitle,
        body: modelFieldMessages.sendPanelToPLCTBody,
        actionData: {
          target: 'control',
          source: 'ordersqueue',
          identifier: 'activate-order',
          items: 'selected',
          callType: 'POST'
        }
      }
    },
    showOrderPanel: {
      adminRequired: false,
      key: 'showOrderPanel',
      format: {
        type: 'action',
        modalTo: '/api/v1/orders/all/',
        icon: 'info',
        style: { fontSize: '27px', color: 'black' }
      }
    },
    toggleCombineId: {
      key: 'toggleCombineId',
      type: 'combineIdToggle',
      dispatch: 'toggleCombineId',
      modal: {
        modal: true,
        buttonText: 'toggleCombineId',
        key: 'toggleCombineId',
        action: 'toggleCombineId',
        icon: 'moveIcon',
        actionData: {
          target: 'control',
          source: 'ordersqueue',
          identifier: 'combine-id-toggle',
          items: 'selected',
          callType: 'POST'
        },
        style: { fontSize: '27px', color: 'black' }
      },
      title: modelFieldMessages.finish
    }
  };
}

export default Order;
