/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import React from 'react';
import { useEffect, useState, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { Switch, Route, useHistory } from 'react-router-dom';
import Websocket from '../Websocket';
//import HomePage from '../HomePage/index';
// import NotFoundPage from 'containers/NotFoundPage/Loadable';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// import makeSelectLogin from '../Login/selectors';
import GlobalStyle from '../../global-styles';
import '../../scss/style.scss';
//import ModalManager from '../ModalManager';

const HomePage = React.lazy(() => import(/* webpackChunkName: "HomePage" */ '../HomePage/index'));
const ModalManager = React.lazy(() => import(/* webpackChunkName: "ModalManager" */ '../ModalManager'));
const Insights = React.lazy(() => import('../Insights/index'));

import ProjectList from './config/projectList';
import { makeSelectConfig, makeSelectArchiveMode, makeSelectLoginState, makeSelectKeyboardState } from './selectors';
import { setProjectConfig, setAuthState, setSettings } from './actions';
import { Wrapper } from './AppWrapper';
import auth from '../../utils/auth';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
// import makeSelectLogin from './selectors';
import reducer from './reducer';
import saga from './saga';

/*  LOGIN ACCES */
import UserContext from '../../utils/hooks/userContext';
import { setupLogin } from '../../utils/permissions_roles/loginService';

/* KEYBOARD */
import KeyboardModal from '../../components/KeyboardModal';
import _ from 'lodash';
import { Snackbar } from '@material-ui/core';
import ArchiveSlider from '../Sidebar/ArchiveSlider';
import Indicators from '../Sidebar/Indicators';
import makeSelectModalManager from '../ModalManager/selectors';
import ReactDOM from 'react-dom';
import makeSelectSiderbar from '../Sidebar/selectors';
import { UnitSystemProvider } from '../../utils/hooks/unitSystemContext';
import { RefreshDatesProvider } from '../Websocket/RefreshDatesProvider';

function ArchiveModeSnackbar(props) {
  const snackbarRoot = document.getElementById('snackbarOverlay');
  return ReactDOM.createPortal(
    <Snackbar open={true} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} style={{ zIndex: 9999, position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}>
      <Indicators>
        <ArchiveSlider dispatch={props.dispatch} archive={props.archive} intervalStep={props.intervalStep} />
      </Indicators>
    </Snackbar>,
    snackbarRoot
  );
}

export function App(props) {
  useInjectReducer({ key: 'app', reducer });
  useInjectSaga({ key: 'app', saga });

  const dispatch = useDispatch();
  const history = useHistory();
  //console.log('APP: ', props);
  /* Load App based on environment variable config settings */
  const project_name = process.env.REACT_APP_PROJECT_NAME;
  const projectList = ProjectList;
  const project_configuration = projectList[project_name];

  /* Update global state through global reducer with appropriate project configuration */
  useEffect(() => {
    if (project_configuration) {
      dispatch(setProjectConfig(project_configuration));
    }
  }, [project_configuration]);

  const localStorage = global.window.localStorage;
  const [loginData, setLoginData] = useState(null);
  const [privilege, setPrivilege] = useState(-1);

  /* TEST LOGIN ACCES */
  const initiateLogin = (role_key) => {
    const { permissions, user } = setupLogin(role_key);
    setPrivilege(role_key);
    setCurrentUser({
      permissions,
      user,
      ...currentUser
    });
  };

  useEffect(() => {
    if (props.loginState.loggedIn) {
      const time = 1000 * 60 * 5;
      const interval = setInterval(() => {
        if (loginData) {
          auth.refreshToken();
        }
      }, time);
      return () => clearInterval(interval);
    }
  }, [loginData]);

  // console.log('# ---- props.loginState : ', props.loginState, ' ----------# ');
  // console.log('# ---- # loginData : ', loginData, ' #----------# ');

  const [currentUser, setCurrentUser] = useState(0);
  const value = useMemo(() => ({ currentUser, setCurrentUser }), [currentUser]);
  /* */

  useEffect(() => {
    auth.reload(localStorage.getItem('token'), setLoginData, initiateLogin, setAuthState, setCurrentUser, currentUser);
  }, []);

  useEffect(() => {
    dispatch(setSettings(_.get(currentUser, 'settings', [])));
  }, [currentUser]);

  useEffect(() => {}, [privilege]);

  useEffect(() => {
    if (props.loginState.loggedIn) {
      // // console.log('props.loginState.loggedIn  && loginData != null');
      // auth.reload(localStorage.token, setLoginData);
      setLoginData(props.loginState.user);
    } else {
      auth.reload(localStorage.getItem('token'), setLoginData, initiateLogin, setAuthState, setCurrentUser, currentUser);
    }
  }, [props.loginState.loggedIn]);

  useEffect(() => {
    initiateLogin(props?.loginState?.privilege);
  }, [props.loginState]);

  useEffect(() => {
    if (loginData != null) {
      dispatch(setAuthState(true, { username: loginData.username, privilege: loginData.privilege }));
      initiateLogin(loginData?.privilege);
    } else {
      dispatch(setAuthState(false));
      initiateLogin(-1);
    }
  }, [loginData]);

  const isAnyModalOpen = props.modalmanager?.modal?.length > 0;

  return (
    <UserContext.Provider value={value}>
      <UnitSystemProvider>
        <RefreshDatesProvider>
          <Wrapper className={`${'app--shell'}${props.archiveMode ? ' archive' : ''}`}>
            <Helmet titleTemplate="%s - XLAM Manager" defaultTitle={project_name}>
              <meta name="description" content="XLAM Manager application" />
            </Helmet>
            <Websocket />
            <Switch>
              <Route path="/" name="Homepage" render={(props) => <HomePage {...props} />} />
            </Switch>
            <ModalManager archiveMode={props.archiveMode} />
            <KeyboardModal />
            <GlobalStyle />
            {isAnyModalOpen && props.archiveMode && <ArchiveModeSnackbar dispatch={dispatch} archive={props.archiveMode} intervalStep={props.sidebar.archiveIntervalStep} />}
          </Wrapper>
        </RefreshDatesProvider>
      </UnitSystemProvider>
    </UserContext.Provider>
  );
}

App.propTypes = {
  children: PropTypes.node,
  loggedIn: PropTypes.object,
  archiveMode: PropTypes.bool
};

const mapStateToProps = createStructuredSelector({
  loginState: makeSelectLoginState(),
  configuration: makeSelectConfig(),
  archiveMode: makeSelectArchiveMode(),
  keyboardState: makeSelectKeyboardState(),
  modalmanager: makeSelectModalManager(),
  sidebar: makeSelectSiderbar()
});

export default connect(mapStateToProps)(App);
