import styled from 'styled-components';

export default styled.div`
  .box {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .layerBtn {
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3.5em;
    width: 100%;
    font-size: 16px;
    text-align: left;
    font-weight: bold;
    border: 2px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden;
    transition: 0.3s;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    margin-bottom: 10px;
    background-color: #f8f9fa;
    color: #333;

    &:hover {
      background-color: #e9ecef;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
  }
  .icon {
    transition: transform 0.3s ease-in-out;
  }
  .hord {
    position: fixed;
    display: inline-block;
    opacity: 1;
    background-color: white;
    width: 200px;
    border-radius: 5px;
    text-align: left;
    font-size: 19px;
    border: 1px solid transparent;
    font-family: 'Montserrat';
    font-weight: bold;
    padding-bottom: 10px;
  }
  .genv {
    padding-left: 10px;
    padding-right: 10px;
  }
  .gena {
    margin-top: 20px;
    padding-left: 20px;
    max-height: 60vh;
    overflow-y: auto;
  }
  .accord {
    margin-top: 20px;
    max-height: 60vh;
    overflow-y: auto;
  }
`;
