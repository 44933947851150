import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';
import AllOutIcon from '@material-ui/icons/AllOut';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import InfoIcon from '@material-ui/icons/Info';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import BuildIcon from '@material-ui/icons/Build';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import HeightIcon from '@material-ui/icons/Height';
import KeyboardTabIcon from '@material-ui/icons/KeyboardTab';
import LineWeightIcon from '@material-ui/icons/LineWeight';
import SortIcon from '@material-ui/icons/Sort';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Filter1Icon from '@material-ui/icons/Filter1';
import Filter2Icon from '@material-ui/icons/Filter2';
import Filter3Icon from '@material-ui/icons/Filter3';
import Filter4Icon from '@material-ui/icons/Filter4';
import Filter5Icon from '@material-ui/icons/Filter5';
import Filter6Icon from '@material-ui/icons/Filter6';
import Filter7Icon from '@material-ui/icons/Filter7';
import Filter8Icon from '@material-ui/icons/Filter8';
import Filter9Icon from '@material-ui/icons/Filter9';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import TurnSlightLeftOutlinedIcon from '@mui/icons-material/TurnSlightLeftOutlined';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import PrintIcon from '@material-ui/icons/Print';
import ErrorStop from '@mui/icons-material/Report';
import CatchingPokemonIcon from '@mui/icons-material/CatchingPokemon';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import InputIcon1 from '@material-ui/icons/FormatIndentIncrease';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import StorageIcon from '@mui/icons-material/Storage';
import CompressIcon from '@mui/icons-material/Compress';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import PowerInputIcon from '@mui/icons-material/PowerInput';
import WrapTextIcon from '@mui/icons-material/WrapText';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ViewArrayIcon from '@mui/icons-material/ViewArray';
import GridViewIcon from '@mui/icons-material/GridView';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight';
import AlignVerticalTopIcon from '@mui/icons-material/AlignVerticalTop';
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import SwipeUpIcon from '@mui/icons-material/SwipeUp';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import SwipeVerticalIcon from '@mui/icons-material/SwipeVertical';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import PlayForWorkIcon from '@mui/icons-material/PlayForWork';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import LineStyleIcon from '@mui/icons-material/LineStyle';
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
import CachedIcon from '@mui/icons-material/Cached';
import DehazeIcon from '@mui/icons-material/Dehaze';
import KeyboardHideIcon from '@mui/icons-material/KeyboardHide';
import DownloadIcon from '@mui/icons-material/Download';
import HomeIcon from '@mui/icons-material/Home';
import TimelineIcon from '@mui/icons-material/Timeline';
import ArrowBack from '@mui/icons-material/ArrowBack';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import CloseIcon from '@mui/icons-material/Close';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SwipeDownOutlinedIcon from '@mui/icons-material/SwipeDownOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import CircleIcon from '@mui/icons-material/Circle';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import BoltIcon from '@mui/icons-material/Bolt';
import SendIcon from '@mui/icons-material/Send';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import KeyIcon from '@mui/icons-material/Key';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import ClearIcon2 from '@mui/icons-material/Clear';
import GroupsIcon from '@mui/icons-material/Groups';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import UpdateIcon from '@mui/icons-material/Update';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import UndoIcon from '@mui/icons-material/Undo';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import StraightenSharpIcon from '@mui/icons-material/StraightenSharp';
import { ForestOutlined } from '@mui/icons-material';

import { JoinInner } from '@mui/icons-material';

export const ClickDownIcon = SwipeDownOutlinedIcon;
export const CopyIcon = ContentCopyIcon;
export const IconClose = CloseIcon;
export const ExitIcon = TurnSlightLeftOutlinedIcon;
export const IconHome = HomeIcon;
export const FileUpload = FileUploadIcon;
export const DoubleDoneIcon = DoneAllRoundedIcon;
export const ConnectionStatusIcon = SettingsInputAntennaIcon;
export const MoreIcon = AllOutIcon;
export const SettingsIcon = DataSaverOnIcon;
export const SendSignalIcon = BlurOnIcon;
export const Info = InfoIcon;
export const DeleteIcon = DeleteForeverOutlinedIcon;
export const FixIcon = BuildIcon;
export const MoveIcon = CallSplitIcon;
export const EditIcon = BorderColorIcon;
export const RightIcon = ArrowRightIcon;
export const LeftIcon = ArrowLeftIcon;
export const ThicknessIcon = HeightIcon;
export const WidthIcon = KeyboardTabIcon;
export const PackageIcon = LineWeightIcon;
export const SortingIcon = SortIcon;
export const PlanerIcon = FilterNoneIcon;
export const NumberSetIcon = FormatListNumberedIcon;
export const GoldenEyeIcon = VisibilityOutlinedIcon;
export const MoveUp = ArrowUpwardIcon;
export const MoveDown = ArrowDownwardIcon;
export const General = AssignmentIcon;
export const Layer1 = Filter1Icon;
export const Layer2 = Filter2Icon;
export const Layer3 = Filter3Icon;
export const Layer4 = Filter4Icon;
export const Layer5 = Filter5Icon;
export const Layer6 = Filter6Icon;
export const Layer7 = Filter7Icon;
export const Layer8 = Filter8Icon;
export const Layer9 = Filter9Icon;
export const Error = ErrorOutlineIcon;
export const AddLayer = LibraryAddIcon;
export const StartButton = PlayCircleOutlineIcon;
export const ArrowUpwardCircled = PlayCircleOutlineIcon;
export const Destination = LowPriorityIcon;
export const ChooseSide = SyncAltIcon;
export const ChangeRoute = ExitToAppIcon;
export const Plus = AddIcon;
export const Minus = RemoveIcon;
export const Print = PrintIcon;
export const ErrorNotAus = ErrorStop;
export const LayerIndex = FilterNoneIcon;
export const AdditionalInfo = InfoIcon;
export const PlcIcon = CatchingPokemonIcon;
export const MachineStatus = EngineeringIcon;
export const Loaders = ViewInArIcon;
export const InputIcon = InputIcon1;
export const Change = MoveDownIcon;
export const Storage = StorageIcon;
export const PressIcon = CompressIcon;
export const LayerIcon = CalendarViewWeekIcon;
export const LayerLongShort = PowerInputIcon;
export const ExtraLam = MultipleStopIcon;
export const BoxContainer = CheckBoxOutlineBlankIcon;
export const PanelIcon = ViewArrayIcon;
export const GridView = GridViewIcon;
export const FromLeft = AlignHorizontalLeftIcon;
export const FromRight = AlignHorizontalRightIcon;
export const FromTop = AlignVerticalTopIcon;
export const FromBottom = AlignVerticalBottomIcon;
export const StatsIcon = QueryStatsIcon;
export const GetStatsIcon = ContentPasteGoIcon;
export const MakeSelection = SwipeUpIcon;
export const ClearIcon = HighlightOffIcon;
export const DragIcon = DragIndicatorIcon;
export const AddExtraLamellaIcon = PlaylistAddIcon;
export const SwipeDownIcon = SwipeVerticalIcon;
export const TrashLAmellaIcon = PlaylistRemoveIcon;
export const MoveToTrashIcon = PlayForWorkIcon;
export const CutListModeIcon = AutoFixHighIcon;
export const CutListIcon = LineStyleIcon;
export const ResetAssignPackageIcon = CachedIcon;
export const SinglingIcon = WrapTextIcon;
export const StackingIcon = DehazeIcon;
export const ArrowDownwardIcona = ArrowDownwardIcon;
export const KeyboardIcon = KeyboardHideIcon;
export const LineChartIcon = TimelineIcon;
export const ArrowBackIcon = ArrowBack;
export const SyncIcon = CloudSyncIcon;
export const ShowMore = ExpandMoreIcon;
export const ShowLess = ExpandLessIcon;
export const WarningTriangle = WarningIcon;
export const Circle = CircleIcon;
export const TransferDataIcon = DoneAllIcon;
export const StopIcon = AvTimerIcon;
export const PowerButton = PowerSettingsNewIcon;
export const ControlBoltIcon = BoltIcon;
export const PlaneIcon = SendIcon;
export const UserLoginIcon = PersonOutlineIcon;
export const PrivilegeIcon = KeyIcon;
export const ProductionSettingsIcon = SettingsSuggestIcon;
export const RemoveUserIcon = ClearIcon2;
export const VncUsersIcon = GroupsIcon;
export const AddCircleIcon = AddCircleOutlineIcon;
export const UpdatePressIcon = UpdateIcon;
export const RestartIcon = RestartAltIcon;
export const SendToIcon = SendAndArchiveIcon;
export const Undo = UndoIcon;
export const TrashIcon = DeleteOutlineIcon;
export const UserSettingsIcon = ManageAccountsIcon;
export const SwitchIcon = SwitchAccessShortcutIcon;
export const OneMore = DownloadDoneIcon;
export const ResetMode = DonutLargeIcon;
export const StraightenSharp = StraightenSharpIcon;

export function getIconFromList(iconName) {
  switch (iconName) {
    case 'moveIcon':
      return <MoveIcon />;
    case 'order1':
      return <PressIcon />;
    case 'order2':
      return <LayerLongShort />;
    case 'addRecipe':
      return <AppRegistrationIcon />;
    case 'uploadFile':
      return <FileUpload />;
    case 'deleteIcon':
      return <DeleteIcon />;
    case 'addOrder':
      return <DashboardCustomizeIcon />;
    case 'addOrderWithRecipe':
      return <DashboardCustomizeIcon />;
    case 'addProjectWithRecipe':
      return <DashboardCustomizeIcon />;
    case 'addLayerWithRecipe':
      return <DashboardCustomizeIcon />;
    case 'downloadIcon':
      return <DownloadIcon />;
    case 'switchIcon':
      return <SwitchIcon />;
    case 'joinInner':
      return <JoinInner />;
    case 'plus':
      return <AddIcon />;
    case 'minus':
      return <RemoveIcon />;
    case 'ruler':
      return <StraightenSharpIcon />;
    case 'printIcon':
      return <Print />;
    case 'forestOutlined':
      return <ForestOutlined />;
    default:
      return <Error />;
  }
}
