const smartlam_edit_settings = [
  // {'missing-lamella-request': {
  //     'title' : 'Missing lamella request',
  //     'settings' : [
  //         {'layerId': {'type' : 'status', 'identifier':'id', 'value': 'id'}},
  //         {'numLamellas': {'type' : 'value', 'value': 0}},
  //         {'urgentRequest': {'type' : 'bool', 'value': false}},
  //         ]
  //     }
  // },
  // {'extra-lamella-request': {
  //     'title' : 'Extra lamella request',
  //     'settings' : [
  //         {'lamellaLength': {'type' : 'value', 'value': 16000}},
  //         {'numLamellas': {'type' : 'value', 'value': 0}},
  //         {'lamellaWidth': {'type' : 'status', 'identifier':'lamellaWidth','value': 0}},
  //         {'lamellaThickness': {'type' : 'status', 'identifier':'lamellaThickness','value': 0}},
  //         {'plannedLamellaWidth': {'type' : 'status','identifier':'plannedLamellaWidth', 'value': 0}},
  //         {'plannedLamellaThickness': {'type' : 'status', 'identifier':'plannedLamellaThickness','value': 0}},
  //         {'urgentRequest': {'type' : 'bool', 'value': false}},
  //         ]
  //     }
  // },
  {
    'set-infeed': {
      title: 'Set Infeed',
      settings: [
        { rawSortingLoading: { type: 'status', identifier: 'rawSortingLoading', value: null } },
        {
          allowanceRawInfeed: { type: 'boolStatus', identifier: 'allowanceRawInfeed', value: false }
        },
        {
          numberOfBoardsBeforePlaner: {
            type: 'status',
            identifier: 'numberOfBoardsBeforePlaner',
            value: 0
          }
        },
        {
          allowanceMultiplan: { type: 'boolStatus', identifier: 'allowanceMultiplan', value: false }
        }
      ]
    }
  },
  {
    'set-superplan': {
      title: 'Set Superplan',
      settings: [
        {
          sortingInfeedWidthOffset: {
            type: 'status',
            identifier: 'sortingInfeedWidthOffset',
            value: 0
          }
        },
        {
          sortingInfeedThicknessOffset: {
            type: 'status',
            identifier: 'sortingInfeedThicknessOffset',
            value: 0
          }
        },
        {
          sortingInfeedPlannedWidthOffset: {
            type: 'status',
            identifier: 'sortingInfeedPlannedWidthOffset',
            value: 0
          }
        },
        {
          sortingInfeedPlannedThicknessOffset: {
            type: 'status',
            identifier: 'sortingInfeedPlannedThicknessOffset',
            value: 0
          }
        },
        { sortingInfeedWidth: { type: 'status', identifier: 'sortingInfeedWidth', value: 0 } },
        {
          sortingInfeedThickness: { type: 'status', identifier: 'sortingInfeedThickness', value: 0 }
        },
        {
          sortingInfeedPlannedWidth: {
            type: 'status',
            identifier: 'sortingInfeedPlannedWidth',
            value: 0
          }
        },
        {
          sortingInfeedPlannedThicknessOffset: {
            type: 'status',
            identifier: 'sortingInfeedPlannedThicknessOffset',
            value: 0
          }
        }
      ]
    }
  },
  {
    'set-drying': {
      title: 'Set Drying',
      settings: [
        { manualFilling: { type: 'boolStatus', identifier: 'manualFilling', value: false } },
        { manualFillingEtage: { type: 'status', identifier: 'manualFillingEtage', value: 0 } },
        { manualEmptying: { type: 'boolStatus', identifier: 'manualEmptying', value: false } },
        { manualEmptyingID: { type: 'status', identifier: 'manualEmptyingID', value: 0 } },
        { dryingTime: { type: 'status', identifier: 'dryingTime', value: 0 } }
      ]
    }
  },
  {
    'set-zink': {
      title: 'Set Zink',
      settings: [
        {
          woodBufferForDimension: {
            type: 'boolStatus',
            identifier: 'woodBufferForDimension',
            value: false
          }
        },
        {
          negativeWoodBufferForBetterDimensionNext: {
            type: 'status',
            identifier: 'negativeWoodBufferForBetterDimensionNext',
            value: 0
          }
        },
        {
          woodBufferForDimensionBreak: {
            type: 'status',
            identifier: 'woodBufferForDimensionBreak',
            value: 0
          }
        },
        {
          percentageOfWoodBeforeGoldenEye: {
            type: 'status',
            identifier: 'percentageOfWoodBeforeGoldenEye',
            value: 0
          }
        },
        { changeWidthFrom: { type: 'status', identifier: 'changeWidthFrom', value: 0 } },
        { changeWidthTo: { type: 'value', value: 0 } },
        { changeThicknessFrom: { type: 'status', identifier: 'changeThicknessFrom', value: 0 } },
        { changeThicknessTo: { type: 'value', value: 0 } },
        { changeBoardDimensions: { type: 'bool', value: false } },
        { makeExtraLamellasId: { type: 'value', value: 0 } },
        { makeExtraLamellasNum: { type: 'value', value: 0 } },
        { makeExtraLamellas: { type: 'bool', value: false } },
        { changePlaneThicknessFrom: { type: 'value', value: 0 } },
        { changePlaneThicknessTo: { type: 'value', value: 0 } },
        { changePlaneThickness: { type: 'bool', value: false } },
        { stickerTest: { type: 'bool', value: false } }
      ]
    }
  },
  {
    'set-no-group': {
      title: 'Set No Group',
      settings: [
        { noGroupMode: { type: 'boolStatus', identifier: 'noGroupMode', value: false } },
        {
          noGroupModeWidthTakeaway: {
            type: 'status',
            identifier: 'noGroupModeWidthTakeaway',
            value: 0
          }
        },
        {
          noGroupModeWidthTakeaway: {
            type: 'status',
            identifier: 'noGroupModeWidthTakeaway',
            value: 0
          }
        },
        { noGroupModeCOversize: { type: 'status', identifier: 'noGroupModeCOversize', value: 0 } },
        { noGroupModeLOversize: { type: 'status', identifier: 'noGroupModeLOversize', value: 0 } },
        { kvhOversize: { type: 'status', identifier: 'kvhOversize', value: 0 } },
        { xcutOversize: { type: 'status', identifier: 'xcutOversize', value: 0 } }
      ]
    }
  },
  {
    'set-editpackage': {
      settings: [],
      cherryPick: ['packageId', 'packageNumber', 'identifier', 'averageLength', 'lamellaLength', '', 'pieces', 'piecesUsed']
    }
  },
  {
    'set-editjd': {
      settings: [],
      cherryPick: [
        'lamellaThickness',
        'lamellaWidth',
        'lamellaLength',
        'lamellaPlannedThickness',
        'lamellaPlannedWidth',
        'numLamellas',
        'zincDoneNumLamellas',
        'producingOnZinc',
        'finishedProducingOnZinc',
        'wrapping',
        'orderTypeString',
        'stackingNumOfLayers',
        'isKVH',
        'emptying',
        'emptied',
        'finishedProducingInsZinc',
        'finishedProducingOnZPress',
        'zPressDoneNumLamellas',
        'producingOnZPress',
        'extraLamellaOrder',
        'layerMatrix',
        'omitCalculation',
        'avoidRecipeCorrection'
      ]
    }
  },
  {
    'set-editControlOrder': {
      settings: [],
      cherryPick: ['id', 'woodType', 'pressId', 'melaminGlue', 'status', 'group', 'isKVH', 'sentToPress', 'overrideReadyForPress', 'overrideReadyForPressLevel', 'layers']
    }
  }
];

export default smartlam_edit_settings;
